import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { jobToolFeature } from "../../job-tool.reducer";
import { SubSink } from "subsink";
import { JobCreateActions } from "../jobv2-create-state/jobv2-create.actions";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CONFIGS_KEYS } from "src/app/global.constants";
import { CloseJobV2Component } from "../close-job-componentV2/close-job-componentV2.component";
import { selectFullNameSelected, selectHowHeardSelected } from "../jobv2-create-state/jobv2-create.selectors";
import { isEmpty } from "lodash";

@Component({
    selector: 'app-discard-job',
    standalone: true,
    imports: [
      FreyaCommonModule,
    ],
    templateUrl: './discard-job.component.html',
    styleUrls: ['./discard-job.component.scss']
  })
  export class DiscardJobComponent implements OnInit, OnDestroy {

    constructor(
      private store: Store,
      public ref: DynamicDialogRef,
      private dialogService: DialogService,
    ) { }

    private subs = new SubSink();

    jobConfigs$ = this.store.select(jobToolFeature.selectJobConfigs);
    selectFullNameSelected$ = this.store.select(selectFullNameSelected);
    howHeardSelected$ = this.store.select(selectHowHeardSelected);
    validationErrors$ = this.store.select(jobToolFeature.selectValidationErrors);

    minimumInfoFilled = false;
    howHeardMandatory = false;
    howHeardSelected = false;
    fullNameSelected = false;

    formIsValid = true;

    ngOnInit(): void {

      this.subs.sink = this.jobConfigs$.subscribe((configs) => {
        if (configs[CONFIGS_KEYS.howHeardMandatory]) {
          this.howHeardMandatory = configs[CONFIGS_KEYS.howHeardMandatory];
        }
      });

      this.subs.sink = this.howHeardSelected$.subscribe((howHeardSelected) => {
        this.howHeardSelected = howHeardSelected;
      });

      this.subs.sink = this.selectFullNameSelected$.subscribe((selectFullNameSelected) => {
        this.fullNameSelected = selectFullNameSelected;
      });

      this.subs.sink = this.validationErrors$.subscribe((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          this.formIsValid = false;
        } else this.formIsValid = true;
      });
    }

    ngOnDestroy(): void {
      this.subs.unsubscribe();
    }

    closeJob() {
        this.store.dispatch(JobCreateActions.saveJobForm());
        this.closeDiscardDialog(true);
    }

    closeDiscardDialog(closed: boolean){
        this.ref.close(closed);
    }

    discardOpportunity() {
      this.store.dispatch(JobCreateActions.discardJob());
      this.closeDiscardDialog(true);
    }

    openCloseJobDialog() {
      this.closeDiscardDialog(true);
      return this.dialogService.open(CloseJobV2Component, {
        header: 'Create job and close it right away',
        width: '42rem',
      });
    }

    createJob() {
      this.store.dispatch(JobCreateActions.saveJobForm());
      this.closeDiscardDialog(true);
    }
  }