<p-card>
  <div
    class="header-container"
    (click)="collapseCard($event)"
    (dblclick)="handleDoubleClick($event)"
  >
    <div class="header-with-icon">
      <i class="pi pi-user p-mr-2"></i>
      <h2>Customer details</h2>
    </div>
    <div class="collapse-container">
      <i *ngIf="isCardCollapsed" class="pi pi-chevron-down p-mr-2"></i>
      <i *ngIf="!isCardCollapsed" class="pi pi-chevron-up p-mr-2"></i>
    </div>
  </div>
  <div class="main-content" *ngIf="!isCardCollapsed">
    <div class="one-column-container">
      <div *ngIf="!isCustomerEditMode" class="edit-deselect-buttons">
        <button
        *ngIf="isExistingCustomer && !isCustomerEditMode"
        pButton
        class="p-button-text deselect-btn discard-button"
        label="Select Another"
        icon="pi pi-times"
        (click)="$event.stopPropagation(); deselectCustomer()"
      ></button>
      <button
        *ngIf="isExistingCustomer && !isCustomerEditMode"
        pButton
        class="p-button-text edit-btn"
        label="Edit Customer"
        icon="pi pi-pencil"
        (click)="$event.stopPropagation(); openEditCustomerMode()"
      ></button>
      </div>
      <div *ngIf="isCustomerEditMode" class="accept-discard-buttons">
        <button
          pButton
          class="p-button-text discard-button"
          label="Discard"
          icon="pi pi-times"
          (click)="$event.stopPropagation(); discardEditCustomerChanges()"
        ></button>
        <button
          pButton
          class="p-button-text"
          label="Accept"
          icon="pi pi-check"
          (click)="$event.stopPropagation(); acceptEditCustomerChanges()"
        ></button>
      </div>
      <app-job-search-users
        #fullNameInput
        label="Full Name (Required)"
        placeholder="Customer Name"
        field="fullName"
      >
      </app-job-search-users>
    </div>
    <div class="two-columns-container">
      <div>
        <app-job-search-users
          #emailInput
          label="Email"
          placeholder="john@mail.com"
          field="email"
        ></app-job-search-users>
      </div>
      <div>
        <app-job-search-users
          #phoneInput
          label="Phone"
          placeholder="(xxx)-xxx-xxxx"
          field="phone"
        ></app-job-search-users>
      </div>
    </div>
    <div class="two-columns-container date-company-container">
      <div>
        <label for="timeline" class="label-hack">Moving Date</label>
        <p-calendar
          #movingDateInput
          [tabindex]="jobCreateIndexes['movingDate']"
          [(ngModel)]="movingDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="timeline"
          [style]="{ width: '100%', margin: '0.5rem 0 1.5rem 0' }"
          [inputStyle]="{ width: '100%' }"
          (ngModelChange)="onMovingDateSelect($event)"
        >
          ></p-calendar
        >
      </div>
      <div>
        <app-job-search-users
          #companyInput
          label="Company"
          placeholder="Company name"
          field="company"
        ></app-job-search-users>
      </div>
    </div>
    <p-divider />
    <div class="two-columns-container">
      <div>
        <label for="JobTypeDropdown">Job Type</label>
        <p-dropdown
          #jobTypeInput
          [tabindex]="jobCreateIndexes['jobType']"
          [options]="jobCategories"
          [style]="{ width: '100%', marginTop: '0.5rem' }"
          [disabled]="true"
          id="JobTypeDropdown"
        ></p-dropdown>
      </div>
      <div>
        <label for="customerTypeDropdown">Customer Type</label>
        <p-dropdown
          #customerTypeInput
          [tabindex]="jobCreateIndexes['customerType']"
          [(ngModel)]="customerTypeSelectedOption"
          [options]="customerTypes"
          [style]="{ width: '100%', marginTop: '0.5rem' }"
          (onChange)="onCustomerTypeSelected($event)"
          id="customerTypeDropdown"
        ></p-dropdown>
      </div>
    </div>
    <div class="two-columns-container">
      <div>
        <label for="howHeardDropdown"
          >Referral Source {{ howHeardMandatory ? '(Required)' : '' }}</label
        >
        <p-dropdown
          #referralSourceInput
          [tabindex]="jobCreateIndexes['referralSource']"
          [(ngModel)]="howHeardSelectedOption"
          [options]="howHeardDropdownOptions"
          [style]="{ width: '100%', marginTop: '0.5rem' }"
          [required]="howHeardMandatory"
          (onChange)="onHowHeardSelected($event)"
          id="howHeardDropdown"
        ></p-dropdown>
      </div>
      <div>
        <label for="jobOriginDropdown">Job Origin</label>
        <p-dropdown
          #jobOriginInput
          [tabindex]="jobCreateIndexes['joOrigin']"
          [(ngModel)]="jobOriginSelectedOption"
          [options]="jobOrigins"
          [style]="{ width: '100%', marginTop: '0.5rem' }"
          (onChange)="onJobOriginSelected($event)"
          id="jobOriginDropdown"
        ></p-dropdown>
      </div>
    </div>
  </div>
</p-card>
