<p-card>
    <div class="header-container" (click)="collapseCard()">
        <div class="header-with-icon">
            <i class="pi pi-calendar p-mr-2"></i>
            <h2>Timeline & Availability</h2>
        </div>
        <div class="collapse-container">
            <i *ngIf="isCardCollapsed" class="pi pi-chevron-down p-mr-2"></i>
            <i *ngIf="!isCardCollapsed" class="pi pi-chevron-up p-mr-2"></i>
        </div>
    </div>
    <div *ngIf="!isCardCollapsed" class="main-content">
        <div class="two-columns-container">
            <div>
                <div>
                    <label for="timeline-availability" class="label-hack">Moving Date</label>
                    <p-calendar
                        #timelineMovingDate
                        [tabindex]="jobCreateIndexes['timelineMovingDate']"
                        [(ngModel)]="movingDate"
                        [iconDisplay]="'input'"
                        [showIcon]="true"
                        inputId="timeline-availability"
                        [style]="{'width':'100%', 'margin':'0.5rem 0 1.5rem 0'}"
                        [inputStyle]="{'width':'100%'}"
                        (ngModelChange)="onMovingDateSelect($event)">
                        ></p-calendar>
                </div>
                <div>
                    <label for="start-time" class="label-hack">Start Time</label>
                    <p-dropdown
                        [tabindex]="jobCreateIndexes['timelineStartTime']"
                        [(ngModel)]="selectedTimeSlot"
                        [placeholder]="selectedTimeSlot ? (selectedTimeSlot | freyaDate: 'h:mm a') : 'Select Start Time'"
                        class="full-width-dropdown"
                        (ngModelChange)="selectAvailableTime($event)"
                        placeholder="Select Time"
                        [options]="possibleTimes ? possibleTimes : []"
                        appendTo="body"
                        [style]="{'width':'100%', 'margin':'0.5rem 0 1.5rem 0'}"
                        [inputStyle]="{'width':'100%'}"
                        [disabled]="selectedTimeSlot && (mode === 'edit')"
                    >
                    <ng-template let-time pTemplate="item">
                        <p>{{ time | freyaDate: "h:mm a" }}</p>
                    </ng-template>
                    <ng-template let-time pTemplate="selectedItem">
                        <p>{{ time | freyaDate: "h:mm a" }}</p>
                    </ng-template>
                    </p-dropdown>
                    <p-message
                        *ngIf="!areaId"
                        severity="warn"
                        text="Your job need to be in area to see available time slots.">
                    </p-message>
                    <span>Location Time: {{jobTiming.moving.totalLocationTime | secondsToTimeUnit}}</span>
                    <br>
                    <br>
                    <span>
                        Travel Time:
                        <ng-container *ngIf="jobTiming.moving.totalTravelTime > 0; else showPartial">
                          {{ jobTiming.moving.totalTravelTime | secondsToTimeUnit }}
                        </ng-container>
                        <ng-template #showPartial>
                          {{ jobTiming.moving.partialTravelTime | secondsToTimeUnit }}
                        </ng-template>
                      </span>
                </div>
            </div>
            <div>
                <h5>Distance & Travel Time</h5>
                <div *ngIf="distances && !objectIsEmpty(distances)">
                    <span>
                        Distances are calculated using an average of traffic conditions, times may vary.
                    </span>
                    <table class="distance-matrix">
                        <ng-container *ngFor="let show of distancesToShow">
                            <tr *ngIf="distances[show.key]" [class.total]="show.total">
                                <td *ngIf="distances[show.key].calculating" class="value">
                                    <div class="vertical-container">
                                        <div><i class={{show.labelFrom}}></i></div>
                                        <div class="arrow-container"><i class="pi pi-arrow-down"></i></div>
                                        <div><i class={{show.labelTo}}></i></div>
                                    </div>
                                    <div class="vertical-container">
                                        <div>{{ show.from }}</div>
                                        <div class="calculated-value">Calculating...</div>
                                        <div>{{ show.to }}</div>
                                    </div>
                                </td>
                                <td *ngIf="!distances[show.key].calculating" class="value">
                                    <div class="vertical-container">
                                        <div><i class={{show.labelFrom}}></i></div>
                                        <div class="arrow-container"><i class="pi pi-arrow-down"></i></div>
                                        <div><i class={{show.labelTo}}></i></div>
                                    </div>
                                    <div class="vertical-container">
                                        <div>{{ show.from }}</div>
                                        <div class="calculated-value">{{ distances[show.key].estimatedTime | secondsToTimeUnit }}
                                            ({{ distances[show.key].totalDistance |
                                                distance:distances[show.key].distanceUnits }})</div>
                                        <div>{{ show.to }}</div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <div *ngIf="objectIsEmpty(distances)">
                    <div class="empty-distances-container">
                        Distances & travel time details are not available due to
                        incomplete information.
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-card>