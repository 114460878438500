<div class="page-header-container">
    <div>
        <div class="header-container">
            <h1 *ngIf="!editMode">New Opportunity</h1>
            <h1 *ngIf="editMode">{{ jobCode }}</h1>
        </div>
        <div class="buttons-container">
            <p-button
                *ngIf="!editMode"
                [outlined]="true"
                (click)="createClose()"
                label="Create & Close"
                [disabled]="isCreateJobButtonDisabled()"
                [pTooltip]="isCreateJobButtonDisabled() ? savingDisabledMessage : null"
            ></p-button>
            <p-button
                *ngIf="!editMode"
                (click)="create()"
                label="Create Job"
                [icon]="jobSaving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                [disabled]="isCreateJobButtonDisabled()"
                [pTooltip]="isCreateJobButtonDisabled() ? savingDisabledMessage : null"
            ></p-button>
            <p-button
                *ngIf="editMode"
                (click)="updateJob()"
                label="Update Job"
                [icon]="jobUpdating ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                [disabled]="isUpdateJobButtonDisabled()"
            ></p-button>
        </div>
    </div>
    <div class="errors-container" *ngIf="errorMessages.length > 1">
        <div class="errors-container" *ngFor="let errorMessage of errorMessages">
            <p-messages severity="error">
                <ng-template pTemplate>
                    <div class="error-message">
                        <i class="pi pi-exclamation-triangle"></i>
                        <p>{{errorMessage}}</p>
                    </div>
                </ng-template>
            </p-messages>
        </div>
    </div>
    <div class="errors-container" *ngIf="errorUpdateMessages.length > 1">
        <div class="errors-container" *ngFor="let errorMessage of errorUpdateMessages">
            <p-messages severity="error">
                <ng-template pTemplate>
                    <div class="error-message">
                        <i class="pi pi-exclamation-triangle"></i>
                        <p>{{errorMessage}}</p>
                    </div>
                </ng-template>
            </p-messages>
        </div>
    </div>
</div>