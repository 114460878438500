import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { SubSink } from 'subsink';
import { JobToolFieldsV2, jobToolFeature } from '../../job-tool.reducer';
import { getRawFieldValue } from 'src/app/fields/fields.utils';
import { AddLocationType, FIELD_CONFIG, JOB_CREATE_INDEXES, estimating } from 'src/app/global.constants';
import { JobCreateLocationsActions } from '../jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { cloneDeep } from 'lodash';
import { distinctUntilChanged } from 'rxjs';
import { DropdownChangeEvent } from 'primeng/dropdown';

export interface Location {
    key: AddLocationType;
    label: string;
  }


export type LocationFieldNameSpace = 'buildingType' | 'sqft' | 'stairs' | 'bedrooms' | 'accessNotes' | 'accessOptions';

@Component({
    selector: 'app-jobv2-create-locations-details',
    standalone: true,
    imports: [
        FreyaCommonModule,
    ],
    templateUrl: './jobv2-create-locations-details.component.html',
    styleUrls: ['./jobv2-create-locations-details.component.scss']
})
export class Jobv2LocationsDetailsComponent implements OnInit, OnDestroy {

    @Input()locationType: Location;

    constructor(
        private store: Store,
    ) { }

    private subs = new SubSink();

    fieldsInput$ = this.store.select(jobToolFeature.selectFieldsInput);
    locationsInput$ = this.store.select(jobToolFeature.selectLocationsInputs);

    fields: JobToolFieldsV2 | object;

    address: string;
    buildingType: string;

    STAIRS_DROPDOWN_OPTIONS = estimating.stairsDropDownOptions;
    BEDROOMS_DROPDOWN_OPTIONS = estimating.bedroomDropdownOptions;
    ACCESS_OPTIONS = estimating.accessOptions;

    stairsSelectedOption: string;
    bedroomsSelectedOption: number;
    sqftSelectedOption: number;
    accessNotes: string;
    accessSelectedOptions: string[];

    jobCreateIndexes = JOB_CREATE_INDEXES;

    ngOnInit(): void {
        this.subs.sink = this.fieldsInput$
        .pipe(distinctUntilChanged())
        .subscribe(fields => {
            this.fields = cloneDeep(fields);
            this.sqftSelectedOption = this.fields[FIELD_CONFIG?.[this.locationType.key]?.sqft.name];
            this.accessNotes = this.fields[FIELD_CONFIG?.[this.locationType.key]?.accessNotes.name];
            this.buildingType = this.fields[FIELD_CONFIG?.[this.locationType.key]?.buildingType.name];
            this.bedroomsSelectedOption = this.fields[FIELD_CONFIG?.[this.locationType.key]?.bedrooms.name];
            this.stairsSelectedOption = this.fields[FIELD_CONFIG?.[this.locationType.key]?.stairs.name];
            this.accessSelectedOptions = this.fields[FIELD_CONFIG?.[this.locationType.key]?.accessOptions?.name];
        });

    this.subs.sink = this.locationsInput$
        .pipe(distinctUntilChanged())
        .subscribe(locationsInput => {
            this.address = locationsInput?.[this.locationType.key]?.addressLineOne;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onLocationFieldTyped(option: string, locationType: AddLocationType, namespace: LocationFieldNameSpace) {
        this.store.dispatch(JobCreateLocationsActions.setLocationFields({
            fieldName: FIELD_CONFIG[locationType]?.[namespace]?.name,
            fieldType: FIELD_CONFIG[locationType]?.[namespace]?.type,
            fieldValue: option,
        }));
    }

    onLocationFieldSelected(option: DropdownChangeEvent, locationType: AddLocationType, namespace: LocationFieldNameSpace) {
        this.store.dispatch(JobCreateLocationsActions.setLocationFields({
            fieldName: FIELD_CONFIG[locationType]?.[namespace]?.name,
            fieldType: FIELD_CONFIG[locationType]?.[namespace]?.type,
            fieldValue: option.value,
        }));
    }

    generateId(subKey: string): string | null {
        const key = this.locationType?.key;
        if (key) {
            return (key + subKey);
        } else {
            return null;
        }
    }
}