import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {dayjs} from '@karve.it/core';
import { AssetService } from '@karve.it/features';
import { Asset } from '@karve.it/interfaces/assets';
import { QueryRef } from 'apollo-angular';

import { cloneDeep, flatten, pick } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { EventTypeInfo, JOB_STAGES, JobEventStatus, eventTypeInfoMap } from 'src/app/global.constants';
import { strToTitleCase } from 'src/app/js';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { EstimateHelperService, EventInfo, LocationMap } from 'src/app/services/estimate-helper.service';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { BookingInformation, FullCalendarHelperService } from 'src/app/services/full-calendar-helper.service';
import { PromoteJobService } from 'src/app/services/promote-job.service';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { SubSink } from 'subsink';

import { BaseCalendarEventFragment, CalendarEvent, EditCalendarEventGQL, EstimatesJobFragment, FindGQL, FindQuery, FindQueryVariables, LockWindowGQL, LockWindowMutationVariables } from '../../../generated/graphql.generated';
import { CalendarHelperService } from '../../calendar-helper.service';
import { getJobLocation } from '../../jobs/jobs.util';

import { EventHelperService } from '../../services/event-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';
import { YemboHelperService } from '../../services/yembo-helper.service';
import { AddLocationFormType } from '../../shared/add-location/add-location.component';
import { convertCalendarFormatToStandard} from '../../time';
import { getJobCustomer } from '../../utilities/job-customer.util';

@Component({
  selector: 'app-event-booking',
  templateUrl: './event-booking.component.html',
  styleUrls: ['./event-booking.component.scss']
})
export class EventBookingComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() job: EstimatesJobFragment;
  @Input() event: CalendarEvent;
  @Input() timezone: string;
  @Input() jobInDifferentTimezoneWarning: string;

  subs = new SubSink();

  eventTypeInfoMap = eventTypeInfoMap;

  // Whether the component is loading data
  loading$ = new BehaviorSubject<boolean>(true);

  // COMPONENT STATE VARIABLES
  eventForm: UntypedFormGroup = new UntypedFormGroup({
    date: new UntypedFormControl({ value: undefined, disabled: true }, [Validators.required]),
    time: new UntypedFormControl({ value: undefined, disabled: true }, [Validators.required]),
    assets: new UntypedFormControl({ value: [], disabled: true }, [Validators.required]),
    dockToStart: new UntypedFormControl({value: true, disabled: true}),
    endToDock: new UntypedFormControl({value: true, disabled: true}),
    crewSize: new UntypedFormControl()
  });
  eventFormValues = cloneDeep(this.eventForm.value); // Used to reset

  // BOOKING VARIABLES
  // A list of the required locations that are missing for the event type
  missingLocations = new BehaviorSubject<AddLocationFormType[]>([]);
  // Avaialble start times based on date, ignored if restrctions are disabled
  possibleTimes: number[] = [];
  // Available Assets based on date and start time
  possibleAssets: Asset[] = [];
  // Windows are how the assets and users are sorted, stores raw return
  possibleWindows: FindQuery['find']['windows'] = [];
  // Stores the values for preselected booking info, if any
  bookingInformation: BookingInformation;


  // EVENT VARIABLES
  // Stores info about the event type
  eventTypeInfo: EventTypeInfo;
  // Stores calcualted info about the event
  eventInfo: EventInfo;
  totalLocationTime: number;
  totalTravelTime: number;
  totalTime: number;

  // FIND QUERY
  findQueryRef: QueryRef<FindQuery, FindQueryVariables>;

  // Whether the event ends before the lock date
  isLocked = false;

  formattedLockDate = {
    long: undefined,
    short: undefined,
  };

  // Whether the find query found availability windows that end after the lock date
  // Used to let the user know that such times will not be available
  hasLockedWindows = false;

  constructor(
    private detailsHelperService: DetailsHelperService,
    public estimateHelper: EstimateHelperService,
    private localNotify: FreyaNotificationsService,
    private fcHelper: FullCalendarHelperService,
    private assetService: AssetService,
    private promoteJobService: PromoteJobService,
    private freyaMutate: FreyaMutateService,
    private eventHelper: EventHelperService,
    private lockWindowGQL: LockWindowGQL,
    private editCalendarEventGQL: EditCalendarEventGQL,
    private findGQL: FindGQL,
    public responsiveHelper: ResponsiveHelperService,
    private calendarHelper: CalendarHelperService,
    public freyaHelperService: FreyaHelperService,
    private confirmationService: ConfirmationService,
    private yemboHelper: YemboHelperService,
    private timeZoneHelper: TimezoneHelperService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.reset();

    this.subs.sink = this.estimateHelper.restrictionsEnabled.subscribe((res) => {
      this.reset();
    });

    this.subs.sink = this.freyaHelperService.lockDate$.subscribe((unixLockDate) => {
      this.setIsLocked();
    });
  }

  ngAfterViewInit(): void {
    // Call detect changes to prevent an `ExpressionChangedAfterItHasBeenCheckedError`
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.job) {
      this.reset();
    }

    if (changes.event) {
      this.setIsLocked();
    }

    if (this.job) {
      this.loading$.next(false);
      this.eventTypeInfo = eventTypeInfoMap[this.event.type];

      const missingLocationsValue = [];

      // Verify that all required locations are present
      for (const locationType of this.eventTypeInfo.requiredLocations) {
        if (!getJobLocation(this.job, locationType)) {
          missingLocationsValue.push(locationType);
        }
      }

      this.missingLocations.next(missingLocationsValue);

      if (!getJobLocation(this.job, 'start')){
        return;
      }

      // disable dock for VOSE
      if (this.eventTypeInfo?.enableDock) {
        this.eventForm.controls.dockToStart.enable();
        this.eventForm.controls.endToDock.enable();
        this.eventForm.controls.dockToStart.setValue(true);
        this.eventForm.controls.endToDock.setValue(true);
      } else {
        this.eventForm.controls.dockToStart.setValue(false);
        this.eventForm.controls.endToDock.setValue(false);
        this.eventForm.controls.dockToStart.disable();
        this.eventForm.controls.endToDock.disable();
      }
    }
  }

  reset() {
    this.eventForm.reset(this.eventFormValues);

    // AR: Removed this because it was causing issues with the logic, not sure why it was added in the first place
    // this.eventForm.controls.date.enable();

    this.possibleWindows = [];
    this.possibleAssets = [];
    this.possibleTimes = [];

    this.eventInfo = undefined;
    this.totalLocationTime = undefined;
    this.totalTravelTime = undefined;
    this.totalTime = undefined;

    this.hasLockedWindows= false;
    this.isLocked = false;
    this.formattedLockDate = {
      long: undefined,
      short: undefined,
    };

    this.calculateEventInfo();

    this.manageEventFormDisabled();
  }

  /**
   * Enables or disables specific controls of the event form based on various changes it listens to:
   * - Whether the job is loading
   * - Whether the component is loading
   * - Whether the user has permissions to update the job
   * - Whether any required locations are missing
   * - Whether the user has completed certain parts of the form
   */
  manageEventFormDisabled() {
    this.subs.sink = combineLatest([
      this.estimateHelper.permissionsAndJobLoading,
      this.estimateHelper.restrictionsEnabled,
      this.missingLocations,
      this.loading$.pipe(distinctUntilChanged()),
      this.eventForm.valueChanges,
    ])
      .subscribe(([
        { jobLoading, updateJob },
        restrictionsEnabled,
        missingLocations,
        componentLoading,
      ]) => {

        const formDisabled = jobLoading
          || componentLoading
          || !updateJob
          || (missingLocations.length && restrictionsEnabled)
          || !this.job;

        if (formDisabled) {
          this.eventForm.disable({ emitEvent: false });
          return;
        }

        const dateEnabled = getJobCustomer(this.job?.users) !== 'None';

        this.freyaHelperService.setDisabledControls(dateEnabled, this.eventForm, ['date'], true);

        const timeEnabled = this.eventForm.value.date;

        this.freyaHelperService.setDisabledControls(timeEnabled, this.eventForm, ['time'], true);

        const assetsEnabled = this.eventForm.value.time;

        this.freyaHelperService.setDisabledControls(assetsEnabled, this.eventForm, ['assets'], true);

        this.freyaHelperService.setDisabledControls(true, this.eventForm, [
          'dockToStart',
          'endToDock',
        ], true);
    });

  }

  // FIND QUERY
  /**
   * Find the avaialble times/assets for the selected date
   */
  findTimes() {
    this.loading$.next(true);

    // Reset the inputs incase they have already been set
    this.eventForm.controls.time.setValue(undefined);
    this.eventForm.controls.assets.setValue([]);

    // If we have disabled restrictions then availability doesn't matter to us
    if (!this.estimateHelper.restrictionsEnabled.value) {
      this.assetService.listAssets({}).subscribe((res) => {
        this.possibleAssets = res.data.assets.assets;
      });

      const date = this.eventForm.value.date;

      const timeDate = new Date(convertCalendarFormatToStandard(date, true));

      timeDate.setHours(6);
      timeDate.setMinutes(0);

      this.eventForm.controls.time.setValue(timeDate);
      this.loading$.next(false);

      return;
    }

    this.calculateEventInfo();

    const findVariables = this.getFindQueryVariables();

    // Refetch find query if it's initialized
    if (this.findQueryRef) {
      this.findQueryRef.refetch(findVariables);
      return;
    }

    // Initialize find query if it hasn't been already
    this.initFindQuery(findVariables);
  }

  /**
   * Initialize the find query
   *
   * @param variables Input for the Query, includes date and asset types to use.
   */
  initFindQuery(variables: FindQueryVariables) {
    this.findQueryRef = this.findGQL.watch(variables, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.findQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        const avails = [];
        this.loading$.next(false);

        for (const window of res.data.find.windows) {

          // Ignore windows that end past the lock time
          if (this.freyaHelperService.lockDate > window.end) {
            this.hasLockedWindows = true;
            continue;
          }

          avails.push(...window.startTimes);
          console.log('avails', avails);
        }

        this.possibleWindows = res.data.find.windows;

        if (this.getCurrentTimeWindows().length) {
          this.possibleTimes = ['Now', ... new Set(avails)];
        } else {
          this.possibleTimes = [... new Set(avails)];
        }

        this.loading$.next(false);

        // We have been passed info from another component
        if (this.bookingInformation) {

          // The selected time does not match the length requirements in the find query
          if (!this.possibleTimes.includes(this.bookingInformation.time)) {
            return;
          }

          // Set the time
          const time = this.bookingInformation.time;
          this.eventForm.controls.time.setValue(time);

          // set the asset from possible assets
          this.setPossibleAssets();
          const asset = this.possibleAssets.find((a) => a.id === this.bookingInformation.assetId);
          this.eventForm.controls.assets.setValue([asset]);

          // Reset the Value
          this.bookingInformation = undefined;
        }
      }
    });
  }

  /**
   * Caclulate the values for the FInd Query
   */
  getFindQueryVariables() {
    const formattedDate = convertCalendarFormatToStandard(this.eventForm.value.date);

    return {
      timeWindow: {
        startDate: formattedDate,
        endDate: formattedDate,
        minWindowLength: this.totalTime,
      },
      minNumAssets: 1,
      assetsFilter: {
        types: eventTypeInfoMap[this.event.type].assetTypes,
      },
      overrideUnavailabilities: !this.estimateHelper.restrictionsEnabled.value,
      zoneInput: this.job.zone.id
    } as FindQueryVariables;
  }

  /**
   * Get the start time, in unix seconds, from the eventForm
   *
   * @returns unix start time in seconds
   */
  getStartUnix(): number {
    const time: number | Date | string = this.eventForm.value.time;
    console.log('time from getUnix', time);
    // Default, with restrictions ENABLED
    if (typeof time === 'number') {
      return time;
    } else if (typeof time === 'string'){
      return this.getNowAsTime().unix();
    }

    // Otherwise, we have restrictions DISABLED and we have to convert the calendar "time," which is
    // a Date() type

    // Convert formatted to dayJs and provide a timezone
    const timezone = this.timezone;
    const dayJsStart = dayjs.tz(time, timezone);

    // return unix seconds
    return dayJsStart.unix();
    }

  /**
   * Creates the placeholder 'Time Lock' event to reserve the time
   */
  async book() {
    if (!this.job) { return; }
    this.loading$.next(true);

    const val = this.eventForm.value;
    const time = this.getStartUnix();

    console.log('TIM FROM BOOK', time);

    // const time = this.estimateHelper.restrictionsEnabled.value ? val.time : Math.floor(val.time / 1000);
    // debugger;
    console.log(`Locking for unix time: ${ time }`);

    const locationMap = await this.estimateHelper.generateLocationTypeMap(this.job);
    this.calculateEventInfo(locationMap);
    // const eventInfo = this.estimateHelper.precalculateEventInfo(this.eventType, locationMap, val.dockToStart, val.endToDock);
    // this.estimateHelper.updateDurationsFromCharges(eventInfo, this.job.charges);

    const { duration } = this.estimateHelper.calculateEventTiming(this.eventInfo, time);

    const input = {
      assetIds: val.assets.map((a) => a.id),
      type: this.event.type,
      title: this.eventTypeInfo?.name || strToTitleCase(this.event.type),
      start: time,
      end: time + duration,
      jobId: this.job.id,
      updateEventSequentialOrder: true,
      eventId: this.event?.id,
      attributes: [this.event.type],
      locations: this.getLocationsForEvent(),
      overrideAvailabilities: !this.estimateHelper.restrictionsEnabled.value,
      overrideUnavailabilities: !this.estimateHelper.restrictionsEnabled.value,
      modifyStartForTravel: Boolean(val.dockToStart),
      modifyEndForTravel: Boolean(val.endToDock),
    } as LockWindowMutationVariables;

    this.lockWindowGQL.mutate(input, {
      context: {
        headers: {
          'x-zone': this.job.zone.id,
        }
      }
    })
      .subscribe(async (res) => {
        this.event = res.data.lockWindow as unknown as CalendarEvent;
        this.setIsLocked();

        // Promote job to booking if we haven't already
        await this.promoteToBooking();
        // await this.eventHelper.updateEventStatus(this.event.id, 'booked');

        if (this.yemboHelper.yemboEnabled && this.event.type === 'virtualEstimate'){
          this.localNotify.success('Yembo link sent to customer email');
        }

        this.detailsHelperService.pushUpdate({
          action: 'create',
          type: 'Events',
          id: this.event.id,
        });
      }, (err) => {
        console.error(`Could not lock time`, err);
        this.localNotify.error('Failed to lock time', err, 10000);
        this.loading$.next(false);
        this.calendarHelper.refreshScheduleData.next();
      });
  }

  async tryBookEvent(){
    if (this.yemboHelper.yemboEnabled && this.event.type === 'virtualEstimate'){
      const assets = this.eventForm.value?.assets;
      const assetsHaveYemboEmail = assets.find((a: Asset) => (Boolean(a.metadata?.yemboEmail)));
      if (!assetsHaveYemboEmail){
        this.confirmationService.confirm({
        header: 'No Yembo Email',
        message: 'This asset does not have a yembo email configured. Smart Consult will be booked for default admin email',
        accept: () => {
          this.book();
        },
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel'
        });
      } else {
        this.book();
      }
    } else {
      this.book();
    }
  }

  getLocationsForEvent() {
    let locations = this.eventInfo.locations?.map((l) => pick(l,
      'type', 'estimatedTimeAtLocation', 'order', 'locationId', 'travelTimeToNextLocationOffset',
    ));

    // Location ID is not nullable in lockWindow mutation
    locations = locations.filter((loc) => loc.locationId);

    return locations;
  }

  markEvent(event: BaseCalendarEventFragment, status: JobEventStatus) {
    this.loading$.next(true);

    const id = event.id;
    const edit = { status };
    event.status = status;
    return this.editCalendarEventGQL.mutate({ ids: [ id ], edit }).subscribe(() => {
      this.detailsHelperService.pushUpdate({
        id,
        type:'Events',
        action:'update'
      });

      this.localNotify.success(`Event ${ status }`);
      this.loading$.next(false);
    }, (err) => {
      this.localNotify.apolloError(`Failed to mark events as ${ status }`, err);
      this.loading$.next(false);
    });

  }

  // HELPERS

  /**
   * If the job is in stage lead or is in stage estimate then promote it to booking
   */
  async promoteToBooking() {

    const leadOrEstimate = [JOB_STAGES.estimate.name, JOB_STAGES.lead.name];
    const hasRevenueGeneratingEvents = Boolean(
      this.job.events.find((event) => eventTypeInfoMap[event.type].revenueGenerating),
    );

    // const leadOrEstimate = [this.job?.stage === JOB_STAGES.estimate.name || this.job?.stage === JOB_STAGES.lead.name];
    const requiresPromotionToBooking = leadOrEstimate.includes(this.job?.stage) && hasRevenueGeneratingEvents;

    if (!requiresPromotionToBooking) { return; }

    const { promotedToStage } = await this.promoteJobService.promote(
      this.job, undefined, undefined,
      JOB_STAGES.booking.name
    );

    /**
     * Because "promoteJobService.promote" will only promote
     * one stage at a time (ie lead -> estimate not lead -> booking)
     * we need to build a better handler for going lead -> booking
     *
     * For now, however, this will do, where we just promote it again.
     *
     * Hacky workaround to promote the job again if needed
     */
    if (promotedToStage === JOB_STAGES.estimate.name) {
      this.job.stage = JOB_STAGES.estimate.name;
      const {} = await this.promoteJobService.promote(
        this.job, undefined, undefined,
        JOB_STAGES.booking.name
      );
    }

    this.job.stage = JOB_STAGES.booking.name;
  }

  /**
   * Set which assets you can select based on the date and time
   */
  setPossibleAssets() {
    let assets = [];

    if (this.eventForm.controls.time.value === 'Now'){
      assets = this.getCurrentTimeWindows().map((w) => w.assets);
    } else {
      assets = this.possibleWindows.filter((w) => w.startTimes.find((st) => st === this.eventForm.controls.time.value))
      .map((w) => (w.assets));
    }

    const assetList = flatten(assets);
    const assetIds = new Set(assetList.map((a) => a.id));

    this.possibleAssets = [];
    for (const id of assetIds){
      this.possibleAssets.push(assetList.find((asset) => asset.id === id));
    }

  }

  /**
   * Gets the possible windows that span the current time
   */
  getCurrentTimeWindows() {
    const nowTime = this.getNowAsTime();
    return this.possibleWindows.filter((window) => window.start < nowTime.unix() && window.end > nowTime.unix());
  }

  /**
   * Reolves the equivalent of 'Now' in yembo
   */
  getNowAsTime(): dayjs.Dayjs {
    const timezone = this.timezone;
    let nowTime = dayjs().tz(timezone);
    const currentMinutes = nowTime.get('minutes');

    nowTime = nowTime.set('minutes', currentMinutes - (currentMinutes % 15)).set('seconds', 0);

    return nowTime;
  }

  /**
   * Cancels an event (by soft deleting it)
   */
  cancelEvent() {
    if (!this.event) { return; }
    this.loading$.next(true);

    this.freyaMutate.openDeleteObject({
      objectId: this.event.id,
      objectName: this.event.title,
      objectType: 'calendarEvent',
      showInsteadOfCancel: `Don't Cancel this Event`,
      showInsteadOfDelete: 'Cancel this Event',
      customTitle: 'Cancel Event?',
      afterDelete: () => {
        this.loading$.next(false);
        this.event = undefined;
        this.setIsLocked();
        this.reset();
      },
      afterCancel: () => {
        this.loading$.next(false);
        this.reset();
      }
    });
  }

  goToEventDate(time: number){
    const date = new Date(time * 1000);
    this.fcHelper.changeCalendarDate.next(date);
  }

  /**
   * Changes the date for the attached calendar, provided one exists
   */
  changeCalendarDate() {
    const selectedDate = new Date(this.eventForm.value.date);
    this.fcHelper.changeCalendarDate.next(selectedDate);
  }


  /**
   * Calculation event information from the event type and map
   * based on the selected event booking options and availability
   * selection. Determines duration and start from rules, products,
   * and event type.
   *
   * Sets eventInfo and times.
   *
   * @param locationMap
   * @returns
   */
  calculateEventInfo(locationMap: LocationMap = {}) {
    const val = this.eventForm.value;
    const eventInfo = this.estimateHelper.precalculateEventInfo(
      this.event.type, locationMap,
      val.dockToStart, val.endToDock,
    );

    eventInfo.id = this.event?.id;

    this.estimateHelper.updateDurationsFromCharges(eventInfo, this.job?.charges as any || []);
    // const eventTiming = this.estimateHelper.calculateEventTiming(eventInfo);
    this.eventInfo = eventInfo;
    this.totalLocationTime = eventInfo.totalLocationTime;
    this.totalTravelTime = eventInfo.totalTravelTime;
    this.totalTime = eventInfo.totalTime;

    // return eventTiming;

//     setTimeout(() => {
//     }, 0);
  }

  setIsLocked() {
    this.isLocked = this.freyaHelperService.lockDate > this.event?.end;
    this.formattedLockDate = {
      long: this.freyaHelperService.getFormattedLockDate(),
      short: this.freyaHelperService.getFormattedLockDate('MMM DD'),
    };
  }

  goToLocationTab() {

    const [ first ] = this.missingLocations.value;

    if (!first) { return; }

    this.estimateHelper.goToLocationsTab(first);
  }

  // async getCrewSize(){
  //   if(this.event){
  //     await this.freyaHelper.getFieldValues(['event.crewSize'], [this.event.id]).then((fields) => {
  //       this.crewSize = fields[0].values[0]?.value;
  //     });
  //   }
  // }

  async rebookUncancelledEvent() {
    this.loading$.next(true);
    // Promote job to booking if we haven't already
    await this.promoteToBooking();
    await this.eventHelper.updateEventStatus(this.event.id, 'booked');

    if (this.yemboHelper.yemboEnabled && this.event.type === 'virtualEstimate'){
      this.localNotify.success('Yembo link sent to customer email');
    }
  }

}
