import { UserProfile } from '@karve.it/interfaces/auth';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ConfigValue, CreateUserMutationVariables, EditProfileMutationVariables, EstimatingCreateJobMutationVariables, SetFieldValuesMutationVariables, UpdateJobMutationVariables } from 'graphql.generated';

import { JobToolState, Tag } from '../../job-tool.reducer';
export const JobCreateActions = createActionGroup({
	source: 'Job Create',
	events: {
		'Init Create Job Tool': emptyProps(),
		'Change Zone': emptyProps(),
		'Get Current User Success': props<{ user: UserProfile }>(),

		'Load Job Configs': props<{ jobConfigsKeys: string[] }>(),
		'Load Job Configs Success': props<{ configValues: ConfigValue[] }>(),
		'Load Job Configs Error': props<{ error: Error }>(),

		'Reset Zone Sensitive Inputs': emptyProps(),

		'Get Currency': emptyProps(),
		'Get Currency Success': props<{ currency: string }>(),

		'Find Times Success': props<{
			result: any,
		}>(),
		'Find Times Error': props<{ error: Error }>(),

		'Select Time Slot': props<{
			timeSlot: number,
		}>(),

		'Save Job Form': emptyProps(),
		'Select Closed Reason': props<{ closedReason: string }>(),

		'Save Job Form And Close Job': props<{ closeJobInput: UpdateJobMutationVariables }>(),
		'Save Job Form And Close Job Success': emptyProps(),
		'Save Job Form And Close Job Error': props<{ error: Error }>(),

		'Discard Job': emptyProps(),

		'Create Job': props<{ createJobVariables: EstimatingCreateJobMutationVariables }>(),
		'Create Job Success': props<{ jobId: string }>(),
		'Create Job Error': props<{ error: Error }>(),

		'Create Customer': props<{ createCustomerVariables: CreateUserMutationVariables }>(),
		'Create Customer Success': props<{ customerId: string }>(),
		'Create Customer Error': props<{ error: Error }>(),

		'Update Customer': props<{ customerEditInput: EditProfileMutationVariables }>(),
		'Update Customer Success': emptyProps(),
		'Update Customer Error': props<{ error: Error }>(),

		'Set Fields': props<{ setFieldsVariables: SetFieldValuesMutationVariables }>(),
		'Set Fields Success': emptyProps(),
		'Set Fields Error': props<{ error: Error }>(),

		'Set Tags': emptyProps(),
		'Set Tags Success': emptyProps(),
		'Set Tags Error': props<{ error: Error }>(),

		'Create Required Event': emptyProps(),
		'Create Required Event Success': emptyProps(),
		'Create Required Event Error': props<{ error: Error }>(),

		'Retrieve Changes From Storage': emptyProps(),
		'Apply Changes From Storage': props<{ retrievedState: JobToolState }>(),
	},
});
