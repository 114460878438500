/* eslint-disable @ngrx/prefix-selectors-with-select */
import { SearchUsersInput } from '@karve.it/interfaces/users';
import { MemoizedSelector, createFeature, createReducer, createSelector, on } from '@ngrx/store';


import { cloneDeep } from 'lodash';

import { BaseFieldFragment, BaseUserFragment, BaseZoneFragment, CommentWithRepliesFragment, FullCalendarEventFragment, FullJobFragment, FullUserFragment, Job, Location } from '../../generated/graphql.generated';

import { Inventory } from '../estimates/estimate.interfaces';
import { ADDABLE_LOCATIONS_V2, DEFAULT_INVENTORY, JOB_CATEGORIES } from '../global.constants';
import { UserProfile } from '../interfaces/auth';
import { currentTimeSeconds } from '../time';
import { CallState, LoadingState } from '../utilities/state.util';




import { addCommentToComments, removeCommentFromArray, updateCommentInPlace } from './job-activity/comments.utils';
import { jobToolSubscriptionReducers } from './job-state/job-tool-subscription.reducers';
import { JobToolActions } from './job-tool.actions';
import { jobCreateCustomerReducers } from './jobv2-create/jobv2-create-customer-state/jobv2-create-customer.reducer';
import { jobCreateInventoryReducers } from './jobv2-create/jobv2-create-inventory-state/jobv2-create-inventory-state.reducer';
import { jobCreateLocationsReducers } from './jobv2-create/jobv2-create-locations-state/jobv2-create-locations-state.reducer';
import { jobCreateReducers } from './jobv2-create/jobv2-create-state/jobv2-create.reducer';
import { jobCreateSelectors } from './jobv2-create/jobv2-create-state/jobv2-create.selectors';
import { jobSummaryReducers } from './jobv2-create/jobv2-create-summary-state/jobv2-create-summary.reducer';
import { AddableLocations, DistanceCalculations, JobTiming } from './jobv2-create/jobv2-interfaces';
import { jobTimelineReducers } from './jobv2-create/jobv2-timeline-availability-state/jobv2-timeline-availability-state.reducer';
import { jobEditReducers } from './jobv2-edit/jobv2-edit-state/jobv2-edit.reducer';
import { jobUpdateSelectors } from './jobv2-edit/jobv2-edit-state/jobv2-edit.selectors';
import { Delta } from 'quill/core';

export type JobToolFieldsV2 = {
	[key: string]: Partial<BaseFieldFragment>
};

// States that are tracked by the job tool under "callState"
export const JOB_TOOL_CALL_STATES = [
	'job',
	'addComment',
	'updateComment',
	'searchCustomer',
	'loadJobConfigs',
	'resolveServiceArea',
	'createLocation',
	'loadDockLocation',
	'calculateDistance',
	'findAvailableTimes',
	'deleteComment',
	'createCustomer',
	'updateCustomer',
	'createJob',
	'updateJob',
	'setFields',
	'setTags',
	'closeJob',
	'loadInventory',
] as const;
export type JobToolCallState = typeof JOB_TOOL_CALL_STATES[number];

export type LocationWithHowSetIndicator = {
	[key: string]: Partial<Location> & { setManually?: boolean };
};

export type EditCustomerInputWithFullName = Partial<FullUserFragment> & {
	fullName?: string;
};

export type JobInputWithSummaries = Partial<Job> & {
	crewSummary?: Summary;
	adminSummary?: Summary;
	customerSummary?: Summary;
};

export interface Summary {
	text?: string;
	contents?: Delta;
}

export interface JobToolComment extends CommentWithRepliesFragment {
	callState?: CallState;
}

export interface ResolvedServiceArea {
	id: string;
	name: string;
}

export type Modes = 'edit' | 'create';

export type FullJobFragmentWithFields = FullJobFragment & {
	fields: Partial<BaseFieldFragment>[];
};

export const jobChangeNamespaces = [
	'fieldsInput',
	'customerInput',
	'locationsInputs',
	'jobInput',
	'inventoryInput'

] as const;

export type JobChangeNamespace = typeof jobChangeNamespaces[number];

export interface JobChange<T = any> {
	namespace: string;
	fieldName: string;
	value: T;

	key?: string;
	remove?: boolean;
}

export type FullUserFragmentWithFullName = Partial<FullUserFragment> & { fullName?: string };

export type Tag = {
	id: string;
	name: string;
}

export type EstimateMethod = 'selfSurvey' | 'smartConsult' | undefined;

export interface JobToolState {
	// the currently logged in user
	user: UserProfile | undefined;

	jobFormMode: Modes;
	jobConfigsToLoadKeys: string[];

	jobId: string | undefined;
	job: FullJobFragmentWithFields | undefined;
	events: FullCalendarEventFragment[] | undefined;
	zone: BaseZoneFragment | undefined;
	comments: JobToolComment[] | undefined;
	totalComments: number | undefined;

	customer: Partial<FullUserFragment> | undefined;
	customerName: string;

	//inputs
	customerInput: FullUserFragmentWithFullName | undefined;
	locationsInputs: LocationWithHowSetIndicator | undefined;
	jobInput: JobInputWithSummaries | undefined;
	inventoryInput: Inventory;
	fieldsInput: {
		[key: string]: string,
	};
	estimateMethod: EstimateMethod,

	//used to calculate final copy
	changes: Array<JobChange>;

	//validation
	validationErrors: { [key: string]: string };

	//calculated data
	addableAdditionalLocations: AddableLocations;
	distances: DistanceCalculations,
	jobTiming: JobTiming;
	selectedTimeSlot: number | undefined;
	jobStartTimeCanBeEdited: boolean;
	editCustomerWhenCreateJobMode: boolean;

	//loaded data based on user inputs
	resolvedServiceArea: ResolvedServiceArea | undefined;
	availableTimeSlots: number[];
	jobTagsIds: Tag[];

	//loaded data based on zone
	jobConfigs: { string: any } | object;
	currency: string | undefined;

	customerSearchInput: SearchUsersInput | object;
	suggestedCustomers: (BaseUserFragment & {
		fullName: string;
	})[];

	closedReason: string | undefined;

	tab: {
		name: string;
		index: number;
	};

	callState: {
		[name in JobToolCallState]: CallState;
	};
}

export const jobToolInitialState: JobToolState = {

	jobFormMode: 'create',

	tab: {
		name: 'overview',
		index: 0,
	},
	callState: JOB_TOOL_CALL_STATES.reduce(
		(p, c) => ({ ...p, [c]: LoadingState.INIT }),
		{} as JobToolState['callState'],
	),

	jobConfigs: {},
	jobConfigsToLoadKeys: [],
	jobTagsIds: [],
	currency: undefined,

	customerName: 'No Customer',
	suggestedCustomers: [],

	user: undefined,
	jobId: undefined,
	comments: undefined,
	customer: undefined,
	events: undefined,
	job: undefined,

	customerInput: undefined,
	jobInput: {
		//as selecting from UI is disabled now, predefine it here
		jobCategory: JOB_CATEGORIES[0],
		metadata: {},
	},
	customerSearchInput: {},
	inventoryInput: cloneDeep(DEFAULT_INVENTORY),
	locationsInputs: undefined,
	fieldsInput: {},
	estimateMethod: undefined,

	addableAdditionalLocations: [...ADDABLE_LOCATIONS_V2],
	resolvedServiceArea: undefined,
	distances: {},
	jobTiming: {
		moving: {
			totalLocationTime: 0,
			totalTime: 0,
			//used when when both start and end locations are set
			totalTravelTime: 0,
			//used when only one location is set
			partialTravelTime: 0,
		}
	},
	availableTimeSlots: [],
	selectedTimeSlot: undefined,

	editCustomerWhenCreateJobMode: false,

	jobStartTimeCanBeEdited: true,

	//validation
	validationErrors: {},

	closedReason: undefined,

	totalComments: undefined,
	zone: undefined,

	changes: [],

};

export type LoadingSelectorType = MemoizedSelector<
	Record<string, any>,
	boolean,
	(s1: JobToolState['callState']) => boolean
>;

export type LoadingErrorSelectorType = MemoizedSelector<
	Record<string, any>,
	boolean,
	(s1: JobToolState['callState']) => string | null
>;

export const jobToolFeature = createFeature({
	name: 'jobTool',
	extraSelectors: ({
		selectCallState,
		selectComments,
	}) => {

		const loadingSelectors = JOB_TOOL_CALL_STATES.reduce((p, callState) => {
			const selectors = {
				[`${callState}Loading`]: createSelector(
					selectCallState,
					(state) => state[callState] === LoadingState.PENDING
						|| state[callState] === LoadingState.LOADING
						|| state[callState] === LoadingState.MUTATING,
				),
				[`${callState}Loaded`]: createSelector(
					selectCallState,
					(state) => state[callState] === LoadingState.LOADED
						|| state[callState] === LoadingState.MUTATED,
				),
				// [`${ callState }FullyLoaded`]: createSelector(
				// 	selectCallState,
				// 	// factorySelectors.
				// 	(state) => {

				// 		const isLoaded = [LoadingState.LOADED, LoadingState.MUTATED]
				// 			.includes(state[callState] as any);
				// 		return isLoaded &&
				// 	},
				// ),
			};

			return {
				...p,
				...selectors,
			};
		}, {} as {
			[x in
			`${JobToolCallState}Loading` |
			`${JobToolCallState}Loaded`
			]: LoadingSelectorType;
		});

		const errorSelectors = JOB_TOOL_CALL_STATES.reduce((p, callState) => {
			// eslint-disable-next-line @ngrx/prefix-selectors-with-select
			const selectors = {
				[`${callState}Error`]: createSelector(
					selectCallState,
					(state) => {
						const cs = state[callState];
						if (typeof cs === 'object' && 'error' in cs) {
							return cs.error;
						}

						return null;
					}
				),
			};

			return {
				...p,
				...selectors,
			};
		}, {} as {
			[x in
			`${JobToolCallState}Error`
			]: LoadingErrorSelectorType;
		});

		const isAnyLoading = createSelector(
			selectCallState,
			(callState) => {
				for (const key in callState) {
					if (callState[key] === LoadingState.LOADING) {
						return true;
					}
					if (callState[key] === LoadingState.MUTATING) {
						return true;
					}
				}

				return false;
			}
		);

		return {
			...loadingSelectors,
			...errorSelectors,
			...jobCreateSelectors,
			...jobUpdateSelectors,
			isAnyLoading,
		};
	},
	reducer: createReducer(
		jobToolInitialState,
		on(JobToolActions.paramsSet, (state, res): JobToolState => {
			return {
				...state,
				user: res.user,
				jobId: res.jobId,
				tab: res.tab,
			};
		}),
		on(JobToolActions.tabChanged, (state, res): JobToolState => {
			return {
				...state,
				tab: {
					index: res.index,
					name: res.name,
				},
			};
		}),
		on(JobToolActions.jobLoading, (state, res): JobToolState => {
			// reset everything about the job state
			// except for a few values
			return {
				...jobToolInitialState,
				user: state.user,
				jobId: state.jobId,
				tab: state.tab,

				callState: {
					...jobToolInitialState.callState,
					job: LoadingState.LOADING,
				}
			};
		}),
		on(JobToolActions.jobLoaded, (state, res): JobToolState => {
			const { job, fields } = res;
			const jobCustomer = job.users?.find((u) => u.role === 'customer');
			const customer = jobCustomer?.user;

			const customerName = [customer.givenName, customer.familyName]
				.filter(Boolean)
				.join(' ');

			return {
				...state,

				job: {
					...job,
					fields,
				},
				jobId: job.id,
				customer: jobCustomer?.user,
				customerName,
				zone: job.zone,

				comments: res.comments,
				totalComments: res.totalComments,

				callState: {
					...state.callState,
					job: LoadingState.LOADED,
				},
			};
		}),
		on(JobToolActions.addComment, (state, { input, temporaryCommentId }): JobToolState => {

			const tempThreadId = `thread:${temporaryCommentId}`;

			const comment: JobToolComment = {
				...input,
				id: temporaryCommentId,
				createdAt: currentTimeSeconds(),
				replies: [],
				author: state.user,
				thread: {
					id: input.threadId || tempThreadId,
				},
				callState: LoadingState.MUTATING,
			};

			const { comments } = addCommentToComments(state.comments, comment);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.addCommentSuccess, (state, res): JobToolState => {

			const comment: JobToolComment = {
				...res.comment,
				callState: LoadingState.MUTATED,
			};

			const { comments } = updateCommentInPlace(
				state.comments,
				res.commentId,
				comment,
				false,
			);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.addCommentError, (state, {
			commentId,
			error,
		}): JobToolState => {

			const comment: Partial<JobToolComment> = {
				callState: {
					error,
				},
			};

			// TODO: is the error because we disconnected?
			// Then mark this comment as PENDING
			// downside of this is that we can only add one pending comment
			// at a time... though if you have an array of pending
			// changes perhaps not?
			// console.error(`Add Comment Error`, state);
			const { comments } = updateCommentInPlace(
				state.comments,
				commentId,
				comment,
				true,
			);

			return {
				...state,
				comments,
				callState: {
					...state.callState,
				},
			};
		}),
		on(JobToolActions.updateComment, (state, { input }): JobToolState => {
			const update = {
				...input.update,
				callState: LoadingState.MUTATING,
			};

			const { comments } = updateCommentInPlace(
				state.comments,
				input.ids[0],
				update,
				true,
			);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.updateCommentSuccess, (state, res): JobToolState => {
			const comment = {
				...res.comment,
				callState: LoadingState.MUTATED,
			};

			const { comments } = updateCommentInPlace(
				state.comments,
				res.comment.id,
				comment,
				false,
			);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.updateCommentError, (state, res): JobToolState => {
			const update = {
				callState: {
					error: res.error,
				},
			} as JobToolComment;

			const { comments } = updateCommentInPlace(
				state.comments,
				res.input.ids[0],
				update,
				true,
			);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.deleteComment, (state, { comment }): JobToolState => {
			const {
				comments,
			} = removeCommentFromArray(
				state.comments,
				comment.id,
			);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.deleteCommentSuccess, (state, { comment }): JobToolState => {
			return {
				...state,
			};
		}),
		on(JobToolActions.deleteCommentError, (state, res): JobToolState => {
			const update = {
				callState: {
					error: res.error,
				},
			} as JobToolComment;

			const { comments } = updateCommentInPlace(
				state.comments,
				res.comment.id,
				update,
				true,
			);
			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.commentLoaded, (state, res): JobToolState => {
			// we can just update the comment in place
			// because this assumes that it has already been
			// added but maybe missing some information (like author)
			const { comments, comment } = updateCommentInPlace(
				state.comments,
				res.comment.id,
				res.comment,
				false,
			);

			return {
				...state,
				comments,
			};
		}),

		on(JobToolActions.inventoryLoading, (state: JobToolState, res): JobToolState => {
			return {
				...state,
				callState: {
					...state.callState,
					loadInventory: LoadingState.LOADING,
				}
			}
		}),
		on(JobToolActions.inventoryLoaded, (state: JobToolState, res): JobToolState => {
			const { __typename, ...inventory } = res.inventory[0];
			return {
				...state,
				job: {
					...state.job,
					fields: [
					  ...state.job.fields,
					  inventory,
					]
				},
				callState: {
					...state.callState,
					loadInventory: LoadingState.LOADED,
				}
			}
		}),
		on(JobToolActions.inventoryLoadError, (state: JobToolState, res): JobToolState => {
			return {
				...state,
				callState: {
					...state.callState,
					loadInventory: {
						error: 'An error occurred during loading inventory',
					}
				}
			}
		}),
		on(JobToolActions.setJobFormMode, (state: JobToolState, res): JobToolState => {
			return {
				...state,
				jobFormMode: res.mode,
			}
		}),
		...jobToolSubscriptionReducers,
		...jobCreateReducers,
		...jobCreateLocationsReducers,
		...jobCreateInventoryReducers,
		...jobTimelineReducers,
		...jobSummaryReducers,
		...jobCreateCustomerReducers,
		...jobEditReducers,
	),
});

