import { on } from '@ngrx/store';


import { cloneDeep } from 'lodash';
import { FIELD_CONFIG, JOB_FORM_FIELDS } from 'src/app/global.constants';
import { LoadingState } from 'src/app/utilities/state.util';

import { JobToolState } from '../../job-tool.reducer';
import { trackChanges, validateEmail, validatePhone, addExistingCustomerFieldsIfDefined } from '../../jobsv2-helpers';

import { JobCreateCustomerActions } from './jobv2-create-customer.actions';

export const jobCreateCustomerReducers = [
    on(JobCreateCustomerActions.searchUsersSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            suggestedCustomers: res.users.map((u) => ({
                ...u,
                fullName: `${u.givenName} ${u.familyName}`.trim(),
            })),
            callState: {
                ...state.callState,
                searchCustomer: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateCustomerActions.searchUsersError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                searchCustomer: {
                    error: res.error.message,
                }
            }
        }
    }),
    on(JobCreateCustomerActions.existingCustomerSelected, (state: JobToolState, res): JobToolState => {
        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.selectedExistingCustomer,
            namespace: 'customerInput',
            value: res.existingCustomer,
        });

        //remove results of search when select existing customer
        const filteredChanges = changes.filter(
            change => change.fieldName !== JOB_FORM_FIELDS.fullName
            && change.fieldName !== JOB_FORM_FIELDS.email
            && change.fieldName !== JOB_FORM_FIELDS.phone
            && change.fieldName !== JOB_FORM_FIELDS.company
        );

        return {
            ...state,
            customerInput: res.existingCustomer,
            validationErrors: {},
            changes: filteredChanges,
        };
    }),
    on(JobCreateCustomerActions.customerValueInput, (state: JobToolState, res): JobToolState => {
        let validationErrors = { ...state.validationErrors };

        if (res.field === 'email') {
            if (res.value === '') {
                delete validationErrors.email;
            } else {
                validationErrors = validateEmail(res.value, validationErrors);
            }
        }

        if (res.field === 'phone') {
            if (res.value === '') {
                delete validationErrors.phone;
            } else {
                validationErrors = validatePhone(res.value, validationErrors);
            }
        }

        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS[res.field],
            namespace: 'customerInput',
            value: res.value,
        });

        return {
            ...state,
            customerInput: {
                ...state.customerInput,
                [res.field]: res.value,
            },
            customerSearchInput: res.filter,
            validationErrors,
            changes,
        };
    }),
    on(JobCreateCustomerActions.editCustomerWhenCreateJob, (state: JobToolState): JobToolState => {
        const initialCustomer = cloneDeep(state.customerInput);

        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.initialCustomerCopy,
            namespace: 'customerInput',
            value: initialCustomer
        });

        return {
            ...state,
            editCustomerWhenCreateJobMode: true,
            changes,
        }
    }),
    on(JobCreateCustomerActions.discardCustomerChanges, (state: JobToolState): JobToolState => {
        const changes = state.changes.filter(
            change => change.fieldName !== JOB_FORM_FIELDS.initialCustomerCopy
            && change.fieldName !== JOB_FORM_FIELDS.fullName
            && change.fieldName !== JOB_FORM_FIELDS.email
            && change.fieldName !== JOB_FORM_FIELDS.phone
            && change.fieldName !== JOB_FORM_FIELDS.company
        );
        const restoredCustomer = state.changes.find(
            change => change.fieldName === JOB_FORM_FIELDS.initialCustomerCopy);
        return {
            ...state,
            validationErrors: {},
            customerInput: { ...restoredCustomer.value },
            changes,
            editCustomerWhenCreateJobMode: false,
        }
    }),
    on(JobCreateCustomerActions.acceptCustomerChanges, (state: JobToolState): JobToolState => {
        const changes = state.changes;
        return {
            ...state,
            changes,
            editCustomerWhenCreateJobMode: false,
        }
    }),
    on(JobCreateCustomerActions.deselectCustomer, (state: JobToolState): JobToolState => {
        const changes = state.changes.filter(
            change => change.fieldName !== JOB_FORM_FIELDS.selectedExistingCustomer
            && change.fieldName !== JOB_FORM_FIELDS.initialCustomerCopy
            && change.fieldName !== JOB_FORM_FIELDS.fullName
            && change.fieldName !== JOB_FORM_FIELDS.email
            && change.fieldName !== JOB_FORM_FIELDS.phone
            && change.fieldName !== JOB_FORM_FIELDS.company
        );
        return {
            ...state,
            customerInput: undefined,
            changes,
            validationErrors: {},
        }
    }),
    on(JobCreateCustomerActions.createNewCustomerBasedOnExisting, (state: JobToolState): JobToolState => {

        const selectedExistingCustomer = state.changes.find(
            change => change.fieldName === JOB_FORM_FIELDS.selectedExistingCustomer
        );

        // Filter out existing customer related changes
        const changesWithoutExistingCustomer = state.changes.filter(
            change => change.fieldName !== JOB_FORM_FIELDS.selectedExistingCustomer &&
                change.fieldName !== JOB_FORM_FIELDS.initialCustomerCopy &&
                change.fieldName !== JOB_FORM_FIELDS.fullName &&
                change.fieldName !== JOB_FORM_FIELDS.email &&
                change.fieldName !== JOB_FORM_FIELDS.phone &&
                change.fieldName !== JOB_FORM_FIELDS.company
        );

        const customerFields = addExistingCustomerFieldsIfDefined(
            selectedExistingCustomer.value,
            { label: 'email', value: selectedExistingCustomer.value.email },
            { label: 'phone', value: selectedExistingCustomer.value.phone },
            { label: 'company', value: selectedExistingCustomer.value.company }
        );

        let finalChanges = cloneDeep(changesWithoutExistingCustomer);

        Object.keys(customerFields).forEach((fieldName) => {
            const value = customerFields[fieldName];

            finalChanges = trackChanges(finalChanges, {
                fieldName: fieldName,
                namespace: 'customerInput',
                value: value,
            });
        });

        return {
            ...state,
            changes: finalChanges,
            customerInput: customerFields,
            validationErrors: {},
            callState: {
                ...state.callState,
                createJob: LoadingState.INIT,
            }
        };
    }),
    on(JobCreateCustomerActions.selectHowHeard, (state: JobToolState, res): JobToolState => {
        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.howHeard,
            namespace: 'fieldsInput',
            key: FIELD_CONFIG.customer.howHeard.name,
            value: res.fieldValue,
        });

        return {
            ...state,
            changes,
            fieldsInput: {
                ...state.fieldsInput,
                [FIELD_CONFIG.customer.howHeard.name]: res.fieldValue,
            }
        };
    }),
    on(JobCreateCustomerActions.selectMovingDate, (state: JobToolState, res): JobToolState => {

        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.timeline,
            namespace: 'jobInput',
            value: res.timeline,
         });

        return {
            ...state,
            jobInput: {
                ...state.jobInput,
                timeline: res.timeline,
            },
            changes,
            callState: {
                ...state.callState,
                findAvailableTimes: LoadingState.LOADING,
            },
        };
    }),
    on(JobCreateCustomerActions.selectCustomerType, (state: JobToolState, res): JobToolState => {
        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.jobType,
            namespace: 'jobInput',
            value: res.jobType,
        });

        return {
            ...state,
            jobInput: {
                ...state.jobInput,
                metadata: {
                    ...state.jobInput.metadata,
                    jobType: res.jobType
                }
            },
            changes,
        };
    }),
    on(JobCreateCustomerActions.selectJobOrigin, (state: JobToolState, res): JobToolState => {
        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.jobOrigin,
            namespace: 'jobInput',
            value: res.jobOrigin,
        });

        return {
            ...state,
            jobInput: {
                ...state.jobInput,
                metadata: {
                    ...state.jobInput.metadata,
                    jobOrigin: res.jobOrigin
                }
            },
            changes,
        };
    }),
] as const;