import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from "@ngrx/store";
import { SubSink } from "subsink";
import { QuillEditorComponent, QuillModules } from 'ngx-quill';

import { DistanceService } from "src/app/estimates/distance.service";
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { LibModule } from 'src/app/lib.ts/lib.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { JobChange, Modes, Summary, jobToolFeature } from '../../job-tool.reducer';
import { cloneDeep, isEmpty } from 'lodash';
import { Delta } from 'quill/core';
import { CONFIGS_KEYS, JOB_CREATE_INDEXES, jobSummaryDefaultToolbar } from 'src/app/global.constants';
import { JobSummaryActions } from '../jobv2-create-summary-state/jobv2-create-summary.actions';
import { JobCreateActions } from '../jobv2-create-state/jobv2-create.actions';
import { selectFullNameSelected, selectHowHeardSelected, selectJobSavingErrors, selectJobSavingInProgress } from '../jobv2-create-state/jobv2-create.selectors';
import { TabPanel } from 'primeng/tabview';
import { JobEditActions } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.actions';
import { selectJobUpdatingErrors, selectJobUpdatingInProgress } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors';
import { BehaviorSubject, ReplaySubject, combineLatest, distinctUntilChanged } from 'rxjs';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';

@Component({
    selector: 'app-jobv2-summary',
    standalone: true,
    imports: [
        FreyaCommonModule,
        LibModule,
        SharedModule,
        QuillEditorComponent,
    ],
    templateUrl: './jobv2-summary.component.html',
    styleUrls: ['./jobv2-summary.component.scss']
})
export class Jobv2SummaryComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChildren(QuillEditorComponent) editors: QueryList<QuillEditorComponent>;
    @ViewChildren('tabHeaders', { read: ElementRef }) tabHeaders: QueryList<ElementRef>;
    @ViewChildren(TabPanel) tabPanels: QueryList<TabPanel>;

    constructor(
        private store: Store,
        public distanceService: DistanceService,
        public localNotify: FreyaNotificationsService,
    ) { }

    private subs = new SubSink();

    jobConfigs$ = this.store.select(jobToolFeature.selectJobConfigs);
    howHeardSelected$ = this.store.select(selectHowHeardSelected);
    selectFullNameSelected$ = this.store.select(selectFullNameSelected);

    //validation
    validationErrors$ = this.store.select(jobToolFeature.selectValidationErrors);
    savingDisabledMessage = 'A customer name is required to create this job.';
    formIsValid = true;

    //loading and error handling
    jobSaving$ = this.store.select(selectJobSavingInProgress);
    jobSavingErrors$ = this.store.select(selectJobSavingErrors);
    jobSaving = false;
    errorMessage = '';

    jobUpdating$ = this.store.select(selectJobUpdatingInProgress);
    jobUpdatingErrors$ = this.store.select(selectJobUpdatingErrors);
    jobUpdating = false;
    errorUpdateMessage = '';

    //modes
    mode: Modes;
    jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);

    changes$ = this.store.select(jobToolFeature.selectChanges);
    changes: JobChange[] = [];

    jobInput$ = this.store.select(jobToolFeature.selectJobInput);

    editorsReady$ = new BehaviorSubject<boolean>(false);

    editModules: QuillModules = {
        toolbar: jobSummaryDefaultToolbar,
        keyboard: {
            bindings: {
              submit: {
                key: 'Enter',
                ctrlKey: true,
                handler: (range, context) => {
                  this.moveBackToSummaryTab();
                },
              },
            },
          },
    };

    crewSummary: Summary;
    adminSummary: Summary;
    customerSummary: Summary;

    crewSummaryContents: Delta | string;
    adminSummaryContents: Delta | string;
    customerSummaryContents: Delta | string;
    summariesMap = {};
    jobId: string;
    initialContentLoaded = false;

    jobSummaries = [
        {
            editorId: 'crewEditor',
            key: 'crewSummary',
            name: 'Crew Summary',
            description: 'This summary will be visible for crew and office staff',
        },
        {
            editorId: 'adminEditor',
            key: 'adminSummary',
            name: 'Admin Summary',
            description: 'This summary will be visible for admins',
        },
        {
            editorId: 'customerEditor',
            key: 'customerSummary',
            name: 'Customer Summary',
            description: 'This summary will be visible for customer',
        },
    ];

    howHeardMandatory = false;
    howHeardSelected = false;
    fullNameSelected = false;

    jobCreateIndexes = JOB_CREATE_INDEXES;
    activeTabIndex: number = 0;

    ngOnInit(): void {

        this.summariesMap = {
          crewSummary: this.crewSummaryContents,
          adminSummary: this.adminSummaryContents,
          customerSummary: this.customerSummaryContents,
        }

        this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
          this.mode = jobFormMode;
        });

        this.subs.sink = this.changes$.subscribe((changes) => {
          this.changes = changes;
          this.changes = changes;
        });

        this.subs.sink = this.jobInput$
        .pipe(distinctUntilChanged())
        .subscribe((jobInput) => {
          this.crewSummary = cloneDeep(jobInput?.crewSummary);
          this.adminSummary = cloneDeep(jobInput?.adminSummary);
          this.customerSummary = cloneDeep(jobInput?.customerSummary);
          this.jobId = jobInput.id;

          this.crewSummaryContents = this.handleUndefined(this.crewSummary);
          this.adminSummaryContents = this.handleUndefined(this.adminSummary);
          this.customerSummaryContents = this.handleUndefined(this.customerSummary);
        });

        this.subs.sink = this.jobConfigs$.subscribe((configs) => {
            if (configs[CONFIGS_KEYS.howHeardMandatory]) {
              this.howHeardMandatory = configs[CONFIGS_KEYS.howHeardMandatory];
              this.savingDisabledMessage = 'A customer name and referral source are required to create this job.'
            }
          })

          this.subs.sink = this.howHeardSelected$.subscribe((howHeardSelected) => {
            this.howHeardSelected = howHeardSelected;
          });

          this.subs.sink = this.selectFullNameSelected$.subscribe((selectFullNameSelected) => {
            this.fullNameSelected = selectFullNameSelected;
          });

          this.subs.sink = this.jobSaving$.subscribe((jobSaving) => {
            this.jobSaving = jobSaving;
          });

          this.subs.sink = this.jobSavingErrors$.subscribe((jobSavingErrors) => {
            const defaultError = 'Errors occurred, job could not be created. Resolve these errors and try again.'
            if (jobSavingErrors.length) {
                this.errorMessage = defaultError;
            }
          });

          this.subs.sink = this.jobUpdating$.subscribe((jobUpdating) => {
            this.jobUpdating = jobUpdating;
          });

          this.subs.sink = this.jobUpdatingErrors$.subscribe((jobUpdatingErrors) => {
            const defaultError = 'Errors occurred, job could not be created. Resolve these errors and try again.'
            if (jobUpdatingErrors.length) {
                this.errorUpdateMessage = defaultError;
            }
          });

          this.subs.sink = this.validationErrors$.subscribe((validationErrors) => {
            if (!isEmpty(validationErrors)) {
              this.formIsValid = false;
            } else this.formIsValid = true;
          });
    }

    ngAfterViewInit(): void {
      this.editorsReady$.next(true);
      this.setupTabHeaderClickEvents();
      this.updateEditorContentsIfReady();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();

        this.tabHeaders.forEach((tabHeader) => {
          tabHeader.nativeElement.removeEventListener('click', this.focusEditor);
        });
    }

    updateEditorContentsIfReady(): void {
      combineLatest([this.editorsReady$, this.jobInput$])
          .pipe(distinctUntilChanged())
          .subscribe(([editorsReady, jobInput]) => {
              if (editorsReady && !this.initialContentLoaded) {
                  this.setEditorContents({
                    crewSummary: cloneDeep(jobInput.crewSummary),
                    adminSummary: cloneDeep(jobInput.adminSummary),
                    customerSummary: cloneDeep(jobInput.customerSummary),
                  });
                  this.initialContentLoaded = true;
              }
          });
    }

    setEditorContents(summaries): void {
      this.editors.forEach((editor, index) => {
        const trySetContents = (attempts = 3) => {
          const editorInstance = editor.quillEditor;
          if (editorInstance) {
            const summaryKey = Object.keys(summaries)[index];
            const content = this.handleUndefined(summaries[summaryKey]);
            if (content) {
              editorInstance.setContents(content, 'api');
            }
          } else if (attempts > 0) {
            setTimeout(() => trySetContents(attempts - 1), 100);
          }
        };
        trySetContents();
      });
    }

    handleUndefined(summary: Summary): Delta {
      if (summary?.text) {
          return summary.contents;
      } else {
          return new Delta([{ insert: '' }]);
      }
    }

    onEditorChange(key: string, index: number) {
        const text = this.editors.toArray()[index].quillEditor.getText();
        const contents = this.editors.toArray()[index].quillEditor.getContents();

        this.store.dispatch(JobSummaryActions.updateSummary({
            text,
            contents,
            key,
        }));
    }

    moveBackToSummaryTab() {
        const focusableElements = Array.from(document.querySelectorAll<HTMLElement>('div[tabindex]'))
          .filter(el => el.tabIndex >= 0);

        focusableElements.sort((a, b) => a.tabIndex - b.tabIndex);

        const nextElement = focusableElements[0];
        nextElement.focus();
      }

    createJob() {
        this.store.dispatch(JobCreateActions.saveJobForm());
    }

    updateJob() {
      if (this.changes?.length) {
        this.store.dispatch(JobEditActions.updateFormSaved({ jobId: this.jobId }));
      } else {
        this.localNotify.addToast.next({severity: 'success', summary: 'Job is already up to date'});
      }
    }

    isCreateJobButtonDisabled(): boolean {
        return (this.jobSaving)
          || (!this.howHeardMandatory && !this.fullNameSelected)
          || (this.howHeardMandatory && (!this.fullNameSelected || !this.howHeardSelected) || !this.formIsValid);
    }

    isUpdateJobButtonDisabled(): boolean {
      return this.jobUpdating || !this.fullNameSelected || !this.formIsValid
    }

    setupTabHeaderClickEvents() {
      this.tabHeaders.forEach((tabHeader, index) => {
        tabHeader.nativeElement.addEventListener('click', () => {
          this.focusEditor(index);
        });
      });
    }

    focusEditor(index: number) {
      const editor = this.editors.toArray()[index];
      if (editor) {
        editor.quillEditor.focus();
      }
    }
}