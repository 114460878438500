import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { InventoryItem } from 'src/app/estimates/estimate.interfaces';
import { EstimateMethod } from '../../job-tool.reducer';
export const JobCreateInventoryActions = createActionGroup({
	source: 'Job Create Inventory',
	events: {
		'Inventory Add Room': props<{
			roomName: string,
		 }>(),
		'Inventory Update Room Name': props<{
			roomIndex: number,
			newRoomName: string,
		 }>(),
		'Inventory Delete Room': props<{
			roomIndex: number,
		 }>(),
		'Inventory Item Added To Room': props<{
			roomIndex: number,
			addedItem: InventoryItem,
		}>(),
		'Inventory Item Removed From Room': props<{
			roomIndex: number,
			itemIndex: number,
		 }>(),
		'Inventory Item Quantity Changed': props<{
			newQuantity: number,
			roomIndex: number,
			itemIndex: number,
		}>(),

		'Select Estimate Method': props<{
			estimateMethod: EstimateMethod,
		 }>(),

		'Send Self Survey Link': emptyProps(),
		'Send Self Survey Link Success': emptyProps(),
		'Send Self Survey Link Error':  props<{
			error: Error,
		 }>(),
	},
});
