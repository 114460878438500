import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { QuillEditorComponent } from 'ngx-quill';
import { MenuItem } from 'primeng/api';

import { } from 'quill';
import { SubSink } from 'subsink';

import { AddCommentInput } from '../../../../generated/graphql.generated';
import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { generateUUID } from '../../../utilities/state.util';
import { JobToolActions } from '../../job-tool.actions';
import { jobToolFeature } from '../../job-tool.reducer';
import { commentDefaultToolbar, isQuillEmpty } from '../comments.utils';

const commentModeNames = [
  'Comment',
  // 'Send Email to Customer',
  // 'Send SMS to Customer',
  // 'Send Message to Crew',
  // 'Send Message to Admin',
] as const;
type CommentModeName = typeof commentModeNames[number];

@Component({
  selector: 'app-job-add-comment',
  standalone: true,
  imports: [
    CommonModule,
    QuillEditorComponent,
    FormsModule,
    FreyaCommonModule,
  ],
  templateUrl: './job-add-comment.component.html',
  styleUrl: './job-add-comment.component.scss'
})
export class JobAddCommentComponent implements OnInit {

  @Input() jobId: string;

  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent | undefined;

  mutating$ = this.store.select(jobToolFeature.addCommentLoading);

  subs = new SubSink();
  newCommentContent = {};

  hasPermission = true;

  modules = {
    toolbar: commentDefaultToolbar,
    keyboard: {
      bindings: {
        submit: {
          key: 'Enter',
          ctrlKey: true,
          handler: (range, context) => {
            this.addComment();
          },
        },
      },
    },
  };

  mode = 'Comment';

  closeJobCommentLabel = 'Close Job';
  error: string;

  commentModes: MenuItem[] = commentModeNames.map((label) => ({
    label,
    command: () => this.setMode(label),
  }));

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
      this.setMode('Comment');
  }

  contentChanged(event) {
    // console.log(event, );

    this.updateCloseJobCommentLabel();

    this.error = undefined;
  }

  onEditorCreated() {
    // console.log(`Editor created`);
    // this.editor.quillEditor.keyboard.addBinding({
    //   key: 'Enter',
    //   ctrlKey: true,
    //   handler: function() {
    //     console.log(`Enter pressed!`);
    //   }
    // });
    // this.editor.quillEditor.keyboard.bindings['ctrl_enter'] = {
    //   key: 13,
    //   ctrlKey: true,
    //   handler: (range, ctx) => {
    //     console.log(range, ctx); // if you want to see the output of the binding
    //     // this.editor.insertText(range.index, '\n');
    //   }
    // } as any;
    // console.log(this.editor.quillEditor.keyboard.bindings);
    // const keyboard: any = this.editor.quillEditor.getModule('keyboard');
    // keyboard.bindings['Enter'] = null;
    // this.editor.quillEditor.keyboard.bindings = {
    //   enter: {
    //     key: 13,
    //     handler: () => {
    //       console.log('ENTER PRESEED');
    //       this.addComment();
    //     }
    //   },
    // };

  }

  canAddComment() {
    if (!this.jobId) return false;
    if (this.isEmpty()) return false;
    if (!this.hasPermission) return false;
    return true;
  }

  addComment() {
    if (!this.canAddComment()) { return false; }

    const text = this.editor.quillEditor.getText();
    const contents = this.editor.quillEditor.getContents();

    const input: AddCommentInput = {
      objectId: this.jobId,
      objectType: 'Job',
      text,
      contents: JSON.stringify(contents),
    };

    const temporaryCommentId = generateUUID();
    this.store.dispatch(JobToolActions.addComment({ input, temporaryCommentId }));
    this.clearContent();
    return true;

  }

  clearContent() {
    this.editor?.quillEditor?.setContents([{ insert: '\n' }]);
  }

  addCommentAndCloseJob() {
    console.log(this.newCommentContent);
  }

  updateCloseJobCommentLabel() {
    if (this.isEmpty()) {
      this.closeJobCommentLabel = 'Close job';
      return;
    }

    this.closeJobCommentLabel = `${ this.mode } & close job`;
  }

  isEmpty() {
    return isQuillEmpty(this.editor?.quillEditor);
  }

  setMode(newMode: CommentModeName) {
    this.mode = newMode;
    for (const item of this.commentModes) {
      item.visible = item.label !== newMode;
    }

    this.updateCloseJobCommentLabel();
  }
}
