import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { getFieldValue } from 'src/app/fields/fields.utils';
import { FIELD_CONFIG, JOB_ROLE_MAP } from 'src/app/global.constants';
import { LoadingState } from 'src/app/utilities/state.util';

import { JobToolState } from '../../job-tool.reducer';

export const selectJobToolState = createFeatureSelector<JobToolState>('jobTool');

export const selectTotalTimeForFind = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.jobTiming?.moving?.totalTime
);

export const selectTotalTimeForFindWhenEditJob = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.jobTiming?.moving?.totalTime
);

export const selectCustomerInput = createSelector(
  selectJobToolState,
  (state: JobToolState) => state.customerInput
);

export const selectCurrency = createSelector(
  selectJobToolState,
  (state: JobToolState) => state.currency
);

export const selectZoneIdForFind = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.resolvedServiceArea?.id
);

export const selectCustomerRole = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.jobConfigs['role.customer'],
);

export const selectAuthorId = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.user?.id,
)

export const selectLocationsForAddToJob = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const locationsInputs = state?.locationsInputs;

    if (!locationsInputs) {
      return [];
    }

    return Object.keys(locationsInputs)
      .map(key => {
        const location = locationsInputs[key];
        return {
          locationId: location?.id,
          locationType: key
        };
      });
  }
);

export const selectSummariesForSaving = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const jobInput = state?.jobInput;
    const crewSummary = jobInput?.crewSummary;
    const adminSummary = jobInput?.adminSummary;
    const customerSummary = jobInput?.customerSummary;

    const result: any = {};

    if (crewSummary) {
      result.crewSummary = crewSummary;
    }
    if (adminSummary) {
      result.adminSummary = adminSummary;
    }
    if (customerSummary) {
      result.customerSummary = customerSummary;
    }

    return result;
  }
);

export const selectMetadataForSaving = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const jobInput = state?.jobInput;
    const metadata = jobInput?.metadata;

    if (!metadata) {
      return null;
    }

    const result: any = {};

    // Iterate over the keys in metadata and include only those with non-undefined values
    Object.keys(metadata).forEach((key) => {
      const value = metadata[key];
      if (value !== undefined) {
        result[key] = value;
      }
    });

    return result;
  }
);

export const selectTimelinesForSaving = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const jobInput = state?.jobInput;
    const timeline = jobInput?.timeline;
    const timelineDays = jobInput?.timelineDays;

    const result: any = {};

    if (timeline) {
      result.timeline = timeline;
    }
    if (timelineDays) {
      result.timelineDays = timelineDays;
    }

    return result;
  }
);

export const selectJobCategory = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const jobInput = state?.jobInput;
    return jobInput?.jobCategory?.toLowerCase();
  }
);

export const selectZoneIdForCreatingJob = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const zoneId = state?.resolvedServiceArea?.id;
    return zoneId;
  }
);

export const selectJobCreationVariables = createSelector(
  selectCustomerInput,
  selectCurrency,
  selectAuthorId,
  selectLocationsForAddToJob,
  selectSummariesForSaving,
  selectMetadataForSaving,
  selectTimelinesForSaving,
  selectJobCategory,
  selectZoneIdForCreatingJob,
  (customer, currency, authorId, locations, summaries, metadata, timelines, jobCategory, selectZoneIdForCreatingJob) => {
    return {
      stage: 'lead',
      currency,
      metadata,
      jobCategory,
      ...summaries,
      users: [
        {
          role: JOB_ROLE_MAP.customerRole,
          userId: customer?.id,
        },
        /*{
          role: JOB_ROLE_MAP.salesAgentRole,
          userId: authorId,
        }*/
      ],
      locations: [...locations],
      zoneId: selectZoneIdForCreatingJob,
      ...timelines
    };
  }
);

export const selectJobSavingErrors = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const callState = state?.callState;

    const errorKeys = ['createCustomer', 'createJob', 'setFields', 'setTags'];
    const errors: string[] = [];

    errorKeys.forEach(key => {
      if (callState && callState[key]?.error) {
        errors.push(callState[key].error);
      }
    });

    return errors;
  }
);

export const selectJobSavingInProgress = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const callState = state?.callState;
    const loadingKeys = ['createCustomer', 'updateCustomer', 'createJob', 'setFields', 'setTags', 'closeJob'];

    return loadingKeys.some(key => callState && callState[key] === LoadingState.LOADING);
  }
);

export const selectHowHeardSelected = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const fields = state?.fieldsInput || {};
    const clonedFields = cloneDeep(fields);
    const howHeardSelectedOption = FIELD_CONFIG.customer.howHeard.name;
    return Boolean(howHeardSelectedOption);
  }
);

export const selectHowHeardSelectedOption = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const howHeardSelectedOption = FIELD_CONFIG.customer.howHeard.name;
    return state?.fieldsInput?.[howHeardSelectedOption];
  }
);

export const selectFullNameSelected = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const fullNameSelected = Boolean(state?.customerInput?.fullName);
    return fullNameSelected;
  }
);

export const selectDataToSaveInLS = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    return {
      changes: state?.changes,
      validationErrors: state?.validationErrors,
      addableAdditionalLocations: state?.addableAdditionalLocations,
      distances: state?.distances,
      jobTiming: state?.jobTiming,
      selectedTimeSlot: state?.selectedTimeSlot,
      jobStartTimeCanBeEdited: state?.jobStartTimeCanBeEdited,
      editCustomerWhenCreateJobMode: state?.editCustomerWhenCreateJobMode,
      resolvedServiceArea: state?.resolvedServiceArea,
      availableTimeSlots: state?.availableTimeSlots,
      jobTagsIds: state?.jobTagsIds,
      job: state?.job,
    };
  }
);

export const selectCreateJobCallState = createSelector(
  selectJobToolState,
  (state: JobToolState) => state.callState.createJob
);

export const jobCreateSelectors = {
  selectTotalTimeForFind,
  selectZoneIdForFind,
  selectCustomerRole,
  selectAuthorId,
  selectSummariesForSaving,
  selectMetadataForSaving,
  selectJobSavingErrors,
  selectJobSavingInProgress,
  selectHowHeardSelected,
  selectHowHeardSelectedOption,
  selectFullNameSelected,
  selectZoneIdForCreatingJob,
  selectCreateJobCallState,
};