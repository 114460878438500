<p-card>
    <div class="flex-container header-container" (click)="collapseCard()">
        <div class="header-with-icon">
            <i class="pi pi-box p-mr-2"></i>
            <h2>Inventory</h2>
        </div>
        <div class="collapse-with-info">
            <div>
                <p-tag severity="info" icon="pi pi-eye" value="{{inventoryInput?.totalItems}} items"></p-tag>
                <p-tag severity="info"  icon="pi pi-flag" value="{{inventoryInput?.totalWeight}} lb"></p-tag>
                <p-tag severity="info" icon="pi pi-box" value="{{inventoryInput?.totalVolume}} cu ft"></p-tag>
            </div>
            <div class="collapse-container">
                <i *ngIf="isCardCollapsed" class="pi pi-chevron-down p-mr-2"></i>
                <i *ngIf="!isCardCollapsed" class="pi pi-chevron-up p-mr-2"></i>
            </div>
        </div>
    </div>
    <div class="main-content" *ngIf="!isCardCollapsed">
        <div *ngFor="let room of inventoryInput?.rooms; let i = index" class="flex-container room-container">
            <div class="flex-container first-row">
            <div>
                <p-autoComplete
                    #roomNameSelect
                    [tabindex]="jobCreateIndexes[calculateRoomTabIndex(i)]"
                    [(ngModel)]="room.name"
                    [suggestions]="filteredRoomOptions"
                    (onSelect)="onRoomNameSelected($event, i)"
                    [completeOnFocus]="true"
                    (completeMethod)="onRoomNameInputUpdated($event)"
                    [delay]="100"
                    [placeholder]="'Change Room Name'"
                    [style]="{'width':'100%'}"
                    [inputStyle]="{'width':'100%', 'margin-top':'0.5rem'}"
                >
                </p-autoComplete>
            </div>
            <div class="delete-button">
                <button
                    [tabindex]="jobCreateIndexes[calculateRoomTabIndex(i)] + 1"
                    pButton
                    type="button"
                    icon="pi pi-times"
                    class="p-button-icon-only p-button-text"
                    (click)="onRoomDeleted(i)"
                ></button>
            </div>
            </div>
            <div class="item-search-row">
                <div *ngFor="let item of room?.inventory; let j = index" class="flex-container inventory-item-shifted-container">
                        <div>
                            <input
                                [tabindex]="jobCreateIndexes[calculateRoomTabIndex(i)] + 10"
                                pInputText
                                type="text"
                                [value]="item.name"
                                readonly
                                [style]="{'width':'100%'}"
                                [inputStyle]="{'width':'100%'}"
                            >
                        </div>
                        <div class="item-quantity">
                            <input
                                #itemChangeQuantity
                                [tabindex]="jobCreateIndexes[calculateRoomTabIndex(i)] + 11"
                                pInputText
                                type="number"
                                [(ngModel)]="item.quantity"
                                (ngModelChange)="onItemQuantityChanged($event, i, j)"
                                [style]="{'width':'100%'}"
                                [inputStyle]="{'width':'100%'}"
                            >
                        </div>
                        <div class="delete-button">
                            <button
                                [tabindex]="jobCreateIndexes[calculateRoomTabIndex(i)] + 12"
                                pButton
                                type="button"
                                icon="pi pi-times"
                                class="p-button-icon-only p-button-text"
                                (click)="onItemRemovedFromRoom(i, j)"
                            ></button>
                        </div>
                </div>
            </div>
            <div class="item-search-row">
                <div class="flex-container inventory-item-shifted-container">
                    <div>
                        <p-autoComplete
                            #itemSelect
                            [tabindex]="jobCreateIndexes[calculateRoomTabIndex(i)] + 3"
                            [suggestions]="filteredItemOptions"
                            field="reviewerName"
                            (onSelect)="onItemAddedToRoom($event, i)"
                            [completeOnFocus]="true"
                            [delay]="100"
                            (completeMethod)="searchInventoryItems($event)"
                            [placeholder]="'Enter inventory item'"
                            [style]="{'width':'100%'}"
                            [inputStyle]="{'width':'100%'}"
                            [minLength]="3"
                        >
                        </p-autoComplete>
                    </div>
                </div>
            </div>
        </div>
        <button
            #addRoomBtn
            [tabindex]="jobCreateIndexes['addRoom']"
            pButton
            class="p-button-outlined add-room-btn p-m-1"
            icon="pi pi-plus"
            label="Add Room"
            (click)="onRoomAdded()">
        </button>
        <!--<div class="estimate-methods">
            <div *ngFor="let method of estimateMethods">
                <p-radioButton
                  [name]="'estimateOption'"
                  [value]="method.value"
                  [inputId]="method.id"
                  [(ngModel)]="estimateMethod"
                  (onClick)="selectEstimateMethod(method.value)"
                />
                <label [for]="method.id" class="ml-2">{{ method.label }}</label>
            </div>
        </div>-->
    </div>
</p-card>