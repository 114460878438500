
<div id="header" *ngIf="loaded$ | async">
	<div class="firstRow" *ngIf="job$ | async as job" >
		<h2>
			{{ customerName$ | async }}
			 | 
			<a
				[routerLink]="[ '/job/v2/', job.id, 'overview' ]"
				[queryParams]="{ zone: authSvc.contextedZoneId }"
				(click)="$event.preventDefault(); detailsHelper.open('job', job)"
			>
				{{ job.code }}
			</a>
			 | 
			 {{ job.total / 100 | currency : 'USD' }}
		</h2>

		<button
    		class="p-button p-button-lg p-button-outlined"
    		(click)="onEditClick(job.id)"
  		>
    		Edit
  		</button>
	</div>
	<div class="secondRow"  *ngIf="job$ | async as job">

		<span
			class="job-status {{ job.closedAt ? 'closed' : 'open' }} {{ job.stage }}"
		>
			{{ job.closedAt ? 'Closed' : 'Open' }} 
			{{ job.stage | titlecase }} 
			<ng-container *ngIf="job.closedAt && job.closedReason">
				- {{ job.closedReason }} 
			</ng-container>
		</span>
		<span class="job-name">&nbsp;{{ customerName$ | async }}&nbsp;</span>
		<span class="job-filler">is&nbsp;</span>
		<span class="job-type">{{ job.jobType || 'moving' }}&nbsp;</span>
		<span class="job-filler" *ngIf="job.timeline">on&nbsp;</span>
		<span class="job-timeline" *ngIf="job.timeline">{{ job.timeline }}&nbsp;</span>
		<span class="job-filler">in&nbsp;</span>
		<span class="job-area">{{ (zone$ | async)?.name }}</span>
	</div>
</div>