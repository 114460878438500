<p-dialog
    header="Add Location Manually"
    appendTo="body"
    [(visible)]="visible"
    [modal]="true"
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
    [draggable]="false"
    [resizable]="false"
>
    <p-message
        *ngIf="visible"
        severity="info"
        text="Google does not recognize this exact address, we have autofilled google's closest guess"
    ></p-message>
    <br>
    <br>
    <div [formGroup]="manualLocationForm"> <div>
            <h6>Address</h6>
            <div class="p-grid">
                <div
                    class="p-col-3"
                    *appVar="manualLocationForm.get('streetNumber') as control"
                >
                    <label>Number</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        class="full-width"
                        formControlName="streetNumber"
                        placeholder="1234 *"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >
                    <app-validation-messages
                        [control]="control"
                        [requireTouched]="true"
                    ></app-validation-messages>
                </div>
                <div
                    class="p-col-9"
                    *appVar="manualLocationForm.get('streetName') as control"
                >
                    <label>Street Name</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        class="full-width"
                        formControlName="streetName"
                        placeholder="Example Avenue *"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >
                    <app-validation-messages
                        [control]="control"
                        [requireTouched]="true"
                    ></app-validation-messages>
                </div>
            </div>
            <h6>Zip/Postal Code</h6>
            <div class="p-grid">
                <div
                    class="p-col-12"
                    *appVar="manualLocationForm.get('areaCode') as control"
                >
                    <input
                        type="text"
                        pInputText
                        formControlName="areaCode"
                        placeholder="Area Code *"
                        (keydown.space)="$event.preventDefault();"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >
                    <br>
                    <small>Do not enter any spaces</small>
                    <app-validation-messages
                        [control]="control"
                        [requireTouched]="true"
                    ></app-validation-messages>
                </div>
            </div>

            <h6>Region</h6>
            <div class="p-grid">
                <div
                    class="p-col"
                    *appVar="manualLocationForm.get('city') as control"
                >
                    <label>City</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        formControlName="city"
                        placeholder="City *"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >
                    <app-validation-messages
                        [control]="control"
                        [requireTouched]="true"
                    ></app-validation-messages>
                </div>
                <div
                    class="p-col"
                    *appVar="manualLocationForm.get('country') as control"
                >
                    <label>Country</label>
                    <br />
                    <p-dropdown
                        class="full-width-dropdown"
                        (onChange)="changeRegexValidator()"
                        placeholder="Select Country"
                        [options]="countries"
                        formControlName="country"
                        appendTo="body"
                    ></p-dropdown>
                    <app-validation-messages [control]="control">
                    </app-validation-messages>
                </div>
                <div
                    class="p-col"
                    *appVar="manualLocationForm.get('jurisdiction') as control"
                >
                    <label>Jurisdiction</label>
                    <br />
                    <p-dropdown
                        class="full-width-dropdown"
                        placeholder="Select a Jurisdiction"
                        [options]="manualLocationForm.value.country | jurisdiction"
                        formControlName="jurisdiction"
                        appendTo="body"
                    ></p-dropdown>
                    <small></small>
                    <app-validation-messages [control]="control">
                    </app-validation-messages>
                </div>
            </div>
        </div>
        <h6>Lookup Tools</h6>
        <ul>
            <li>
                <a
                    href="https://tools.usps.com/zip-code-lookup.htm"
                    target="_blank"
                >USPS Lookup</a>
            </li>
            <li>
                <a
                    href="https://www.canadapost-postescanada.ca/info/mc/personal/postalcode/fpc.jsf"
                    target="_blank"
                >Canada Post Lookup</a>
            </li>
        </ul>
    </div>
    <p-message
        severity="warn"
        text="The system will not be able to verify a manually entered location, ensure the values you have entered are correct before proceeding"
    ></p-message>
    <ng-template pTemplate="footer">
        <button
            pButton
            label="Cancel"
            icon="pi pi-times"
            class="p-button-text"
            (click)="onLocationInputBlur()"
        ></button>
        <button
            pButton
            [disabled]="manualLocationForm.invalid"
            label="Add Location"
            icon="pi pi-plus"
            (click)="addLocation()"
        ></button>
    </ng-template>
</p-dialog>
