<p-card>
	<app-job-header></app-job-header>

	<p-tabView
		(onChange)="onTabChange($event.index)"
		[activeIndex]="(tab$ | async).index"
	>
		<p-tabPanel header="Overview">
			<app-job-overview>
			</app-job-overview>
		</p-tabPanel>
		<p-tabPanel header="{{ (events$ | async)?.length > 1 ? 'Workorders' : 'Workorder' }}">
			<ng-template pTemplate="content">
				Workorders - Work In Progress
			</ng-template>
		</p-tabPanel>
		<p-tabPanel header="Financials">
			<ng-template pTemplate="content">
				Financials - Work In Progress
			</ng-template>
		</p-tabPanel>
		<p-tabPanel header="Inventory">
			<ng-template pTemplate="content">
				Inventory - Work In Progress
			</ng-template>
		</p-tabPanel>
		<p-tabPanel header="Files">
			<ng-template pTemplate="content">
				Files - Work In Progress
			</ng-template>
		</p-tabPanel>
	</p-tabView>


	<section class="activity">
		<div class="activity-header">
			<h3>
				Activity
				<small *ngIf="totalComments$ | async">
					{{ totalComments$ | async }}
				</small>
			</h3>
		</div>
		<app-job-activity></app-job-activity>
	</section>
</p-card>
