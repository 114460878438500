import { Component, OnInit, OnDestroy, HostListener, input, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { JobChange, jobToolFeature } from "../../job-tool.reducer";
import { SubSink } from "subsink";
import { CONFIGS_KEYS } from "src/app/global.constants";
import { JobCreateActions } from "../jobv2-create-state/jobv2-create.actions";
import { DialogService } from "primeng/dynamicdialog";
import { CloseJobV2Component } from "../close-job-componentV2/close-job-componentV2.component";
import { selectFullNameSelected, selectHowHeardSelected, selectJobSavingErrors, selectJobSavingInProgress } from "../jobv2-create-state/jobv2-create.selectors";
import { isEmpty } from "lodash";
import { FreyaNotificationsService } from "src/app/services/freya-notifications.service";
import { JobEditActions } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.actions";
import { selectJobUpdatingErrors, selectJobUpdatingInProgress } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors";

@Component({
  selector: 'app-jobv2-create-header',
  standalone: true,
  imports: [
    FreyaCommonModule,
  ],
  templateUrl: './jobv2-create-header.component.html',
  styleUrls: ['./jobv2-create-header.component.scss']
})
export class Jobv2CreateHeaderComponent implements OnInit, OnDestroy {

  @HostListener('window:keydown.control.s', ['$event'])
  onControlS(event: KeyboardEvent) {
    event.preventDefault();
    if (!this.isCreateJobButtonDisabled()) {
      this.create();
    } else {
      this.localNotify.addToast.next({ severity: 'warn', summary: 'Fill out required fields to create job' });
    }
  }

  @HostListener('window:keydown.control.shift.s', ['$event'])
  onControlShiftS(event: KeyboardEvent) {
    event.preventDefault();
    if (!this.isCreateJobButtonDisabled()) {
      this.createClose();
    } else {
      this.localNotify.addToast.next({
        severity: 'warn',
        summary: 'Fill out required fields to create and close job',
      });
    }
  }

  constructor(
    private store: Store,
    private dialogService: DialogService,
    public localNotify: FreyaNotificationsService,
  ) { }

  private subs = new SubSink();

  jobConfigs$ = this.store.select(jobToolFeature.selectJobConfigs);
  selectFullNameSelected$ = this.store.select(selectFullNameSelected);
  howHeardSelected$ = this.store.select(selectHowHeardSelected);
  validationErrors$ = this.store.select(jobToolFeature.selectValidationErrors);

  howHeardMandatory = false;
  howHeardSelected = false;
  fullNameSelected = false;

  jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
  jobInput$ = this.store.select(jobToolFeature.selectJobInput);

  jobCode: string;
  jobId: string;
  editMode: boolean;

  //validation

  savingDisabledMessage = 'A customer name is required to create this job.'
  formIsValid = true;

  //loading and error handling
  jobSavingErrors$ = this.store.select(selectJobSavingErrors);
  jobSaving$ = this.store.select(selectJobSavingInProgress);
  jobSaving = false;
  errorMessages = [
    'Errors occurred, job could not be created. Resolve these errors and try again.'
  ];

  jobUpdating$ = this.store.select(selectJobUpdatingInProgress);
  jobUpdatingErrors$ = this.store.select(selectJobUpdatingErrors);
  jobUpdating = false;
  errorUpdateMessages = [
    'Errors occurred, job could not be updated. Resolve these errors and try again.'
  ]

  changes$ = this.store.select(jobToolFeature.selectChanges);
  changes: JobChange[] = [];

  ngOnInit(): void {
    this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
      this.editMode = jobFormMode === 'edit';
    });

    this.subs.sink = this.changes$.subscribe((changes) => {
      this.changes = changes;
    });

    this.subs.sink = this.jobConfigs$.subscribe((configs) => {
      if (configs[CONFIGS_KEYS.howHeardMandatory]) {
        this.howHeardMandatory = configs[CONFIGS_KEYS.howHeardMandatory];
        this.savingDisabledMessage = 'A customer name and referral source are required to create this job.'
      }
    })

    this.subs.sink = this.howHeardSelected$.subscribe((howHeardSelected) => {
      this.howHeardSelected = howHeardSelected;
    });

    this.subs.sink = this.selectFullNameSelected$.subscribe((selectFullNameSelected) => {
      this.fullNameSelected = selectFullNameSelected;
    });

    this.subs.sink = this.jobSaving$.subscribe((jobSaving) => {
      this.jobSaving = jobSaving;
    });

    this.subs.sink = this.jobSavingErrors$.subscribe((jobSavingErrors) => {
      const defaultError = 'Errors occurred, job could not be created. Resolve these errors and try again.'
      this.errorMessages = [];

      this.errorMessages.push(defaultError);

      jobSavingErrors.forEach(error => {
        this.errorMessages.push(error);
      });
    });

    this.subs.sink = this.jobUpdating$.subscribe((jobUpdating) => {
      this.jobUpdating = jobUpdating;
    });

    this.subs.sink = this.jobUpdatingErrors$.subscribe((jobUpdatingErrors) => {
      const defaultError = 'Errors occurred, job could not be updated. Resolve these errors and try again.'
      this.errorUpdateMessages = [];

      this.errorUpdateMessages.push(defaultError);

      jobUpdatingErrors.forEach(error => {
        this.errorUpdateMessages.push(error);
      });
    });

    this.subs.sink = this.validationErrors$.subscribe((validationErrors) => {
      if (!isEmpty(validationErrors)) {
        this.formIsValid = false;
      } else this.formIsValid = true;
    });

    this.subs.sink = this.jobInput$.subscribe((jobInput) => {
      this.jobCode = jobInput?.code;
      this.jobId = jobInput.id;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  createClose() {
    this.openCloseJobDialog();
  }

  create() {
    this.store.dispatch(JobCreateActions.saveJobForm());
  }

  openCloseJobDialog() {
    return this.dialogService.open(CloseJobV2Component, {
      header: 'Create job and close it right away',
      width: '42rem',
    });
  }

  updateJob() {
    if (this.changes?.length) {
      this.store.dispatch(JobEditActions.updateFormSaved({ jobId: this.jobId }));
    } else {
      this.localNotify.addToast.next({ severity: 'success', summary: 'Job is already up to date' });
    }
  }

  isCreateJobButtonDisabled(): boolean {
    return (this.jobSaving)
      || (!this.howHeardMandatory && !this.fullNameSelected)
      || (this.howHeardMandatory && (!this.fullNameSelected || !this.howHeardSelected) || !this.formIsValid);
  }

  isUpdateJobButtonDisabled(): boolean {
    return this.jobUpdating || !this.fullNameSelected || !this.formIsValid
  }
}